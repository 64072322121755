$(document).ready(function () {


    // --------------  Lubricant Codes Index page ---------------
    var lubricant_codes_index_page = document.querySelector('#lubricant_codes_grid');

    if (lubricant_codes_index_page != undefined) {

        var lubricant_codes_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name: 'Производитель',
                industry_reference: 'Отраслевой справочник',
                lubricant_code_type_name_ru: 'Тип',
                lubricant_code_type_name_en: 'Тип',
                lubricant_code_standard_name: 'Стандарт',
                equipment_component_count: 'Кол-во компонент',
                products_count: "Кол-во продуктов",
            },
            en: {
                created_at: 'Created at',
                name: 'Manufacturer',
                industry_reference: 'Industry reference',
                lubricant_code_type_name_ru: 'Type',
                lubricant_code_type_name_en: 'Type',
                lubricant_code_standard_name: 'Standard',
                equipment_component_count: 'Components count',
                products_count: "Products count",
            }
        };

        function getLubricantCodesColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return lubricant_codes_dictionary[culture][word]
        }

        function resize_window_of_lubricant_codes() {
            $("#lubricant_codes_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window_of_lubricant_codes();
        });

        function lubricant_codes_row_coloring_on_databound() {
            var grid = $("#lubricant_codes_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == 1) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#c5ebc7"); //green
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#eca6b0"); //red deactivated
                }
            });
        }

        $("#lubricant_codes_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/lubricant_codes.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            industry_reference: {type: "string"},
                            lubricant_code_type_id: {type: "integer"},
                            lubricant_code_type_name_ru: {type: "string"},
                            lubricant_code_type_name_en: {type: "string"},
                            lubricant_code_standard_name: {type: "string"},
                            equipment_component_count: {type: "string"},
                            products_count: {type: "string"},
                            is_active: {type: "boolean"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_lubricant_codes();
                lubricant_codes_row_coloring_on_databound();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
//            navigatable: true,
//            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/lubricant_codes/#=id#/">Show</i></a></div>',
                    width: 60
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/lubricant_codes/#=id#/edit">Edit</i></a></div>',
                    width: 60,
                    hidden: !gon.is_editable
                },
                {
                    title: getLubricantCodesColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 150
                },
                {
                    title: getLubricantCodesColumnNameByCulture(gon.locale, 'industry_reference'),  //"Название отчета",
                    field: "industry_reference",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getLubricantCodesColumnNameByCulture(gon.locale, 'lubricant_code_type_name_en'),  //"Название отчета",
                    field: "lubricant_code_type_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },

                {
                    title: getLubricantCodesColumnNameByCulture(gon.locale, 'lubricant_code_standard_name'),
                    field: "lubricant_code_standard_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getLubricantCodesColumnNameByCulture(gon.locale, 'equipment_component_count'),  // Количество компонентов
                    field: "equipment_component_count",
                    filterable: false,
                    sortable: false,
                    width: 100
                },
                {
                    title: getLubricantCodesColumnNameByCulture(gon.locale, 'products_count'),  // Количество продуктов
                    field: "products_count",
                    filterable: false,
                    sortable: false,
                    width: 100
                },



            ]
        });

    }

});