
$(document).ready(function () {

    // --------------  Users Edit page ---------------
    var documentation_page = document.querySelector('#documentation');

    if (documentation_page != undefined) {

        $(".copy_code_button").click(function (e) {
            // console.log($(this).parent().find('.code_text').html());

            value = $(this).parent().find('.code_text').html()

            // console.log(value);
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val(value).select();
            document.execCommand("copy");
            $temp.remove();
        });
    }

});
