$(document).ready(function () {

    // Show or hide mobile menu
    $("#mobile_menu_button").click(function (e) {

        if ($('#mobile-menu').is(":hidden")) {
            $('#mobile-menu').fadeTo(250, 1, function () {
                $(this).show()
            });
        } else {
            $('#mobile-menu').fadeTo(250, 0, function () {
                $(this).hide()
            });
        }

    });

    function showSelectorsMenu(to_show) {
        if (to_show) {
            $("#navbar_selectors_dropdown").removeClass('hidden');
            $("#navbar_selectors_menu").addClass('z-10');
        } else {
            $("#navbar_selectors_dropdown").addClass('hidden');
            $("#navbar_selectors_menu").removeClass('z-10');
        }
    }


    function showEquipmentMenu(to_show) {
        if (to_show) {
            $("#navbar_equipment_dropdown").removeClass('hidden');
            $("#navbar_equipment_menu").addClass('z-10');
        } else {
            $("#navbar_equipment_dropdown").addClass('hidden');
            $("#navbar_equipment_menu").removeClass('z-10');
        }
    }

    function showProductMenu(to_show) {
        if (to_show) {
            $("#navbar_product_dropdown").removeClass('hidden');
            $("#navbar_product_menu").addClass('z-10');
        } else {
            $("#navbar_product_dropdown").addClass('hidden');
            $("#navbar_product_menu").removeClass('z-10');
        }
    }

    function showManagementMenu(to_show) {
        if (to_show) {
            $("#navbar_management_dropdown").removeClass('hidden');
            $("#navbar_management_menu").addClass('z-10');
        } else {
            $("#navbar_management_dropdown").addClass('hidden');
            $("#navbar_management_menu").removeClass('z-10');
        }
    }

    function showAnalyticsMenu(to_show) {
        if (to_show) {
            $("#navbar_analytics_dropdown").removeClass('hidden');
            $("#navbar_analytics_menu").addClass('z-10');
        } else {
            $("#navbar_analytics_dropdown").addClass('hidden');
            $("#navbar_analytics_menu").removeClass('z-10');
        }
    }

    function showSetupMenu(to_show) {
        if (to_show) {
            $("#navbar_setup_dropdown").removeClass("hidden");
            $('#navbar_setup_click').addClass('z-10');
        } else {
            $("#navbar_setup_dropdown").addClass("hidden");
            $('#navbar_setup_click').removeClass('z-10');
        }
    }

    function showUserMenu(to_show) {
        if (to_show) {
            $("#navbar_user_dropdown").removeClass('hidden');
            $("#navbar_user_menu").addClass('z-10');
        } else {
            $("#navbar_user_dropdown").addClass('hidden');
            $("#navbar_user_menu").removeClass('z-10');
        }
    }

    function showLocaleMenu(to_show) {
        if (to_show) {
            $("#navbar_language_select_dropdown").removeClass('hidden');
            $("#navbar_language_select_menu").addClass('z-10');
        } else {
            $("#navbar_language_select_dropdown").addClass('hidden');
            $("#navbar_language_select_menu").removeClass('z-10');
        }
    }

    function showDocumentationMenu(to_show) {
        if (to_show) {
            $("#navbar_documentation_dropdown").removeClass('hidden');
            $("#navbar_documentations_menu").addClass('z-10');
        } else {
            $("#navbar_documentation_dropdown").addClass('hidden');
            $("#navbar_documentations_menu").removeClass('z-10');
        }
    }

    function showGrayOut(to_show) {
        if (to_show) {
            $("#window_grayout").removeClass('hidden');
        } else {
            $("#window_grayout").addClass('hidden');
        }
    }

    // Equipment menu drop down
    $("#navbar_selectors_menu").click(function (e) {
        e.preventDefault();

        if ($("#navbar_selectors_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showSelectorsMenu(true);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showSelectorsMenu(false);
            showGrayOut(false);
        }
    });

    // Equipment menu drop down
    $("#navbar_equipment_menu").click(function (e) {
        e.preventDefault();

        if ($("#navbar_equipment_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showEquipmentMenu(true);
            showUserMenu(false);
            showSetupMenu(false);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showEquipmentMenu(false);
            showGrayOut(false);
        }
    });

    // Product menu drop down
    $("#navbar_product_menu").click(function (e) {
        e.preventDefault();

        if ($("#navbar_product_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showProductMenu(true);
            showEquipmentMenu(false);
            showUserMenu(false);
            showSetupMenu(false);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showProductMenu(false);
            showGrayOut(false);
        }
    });

    // Management menu drop down
    $("#navbar_management_menu").click(function (e) {
        e.preventDefault();

        if ($("#navbar_management_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showManagementMenu(true);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showManagementMenu(false);
            showGrayOut(false);
        }
    });


    // Analytics menu drop down
    $("#navbar_analytics_menu").click(function (e) {
        e.preventDefault();

        if ($("#navbar_analytics_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showAnalyticsMenu(true);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showAnalyticsMenu(false);
            showGrayOut(false);
        }
    });

    // Accounting menu drop down
    $("#navbar_setup_click").click(function (e) {
        e.preventDefault();

        if ($("#navbar_setup_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showEquipmentMenu(false);
            showUserMenu(false);
            showSetupMenu(true);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showSetupMenu(false);
            showGrayOut(false);
        }
    });


    // User menu drop down
    $("#navbar_user_menu").click(function (e) {
        e.preventDefault();
        if ($("#navbar_user_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showEquipmentMenu(false);
            showUserMenu(true);
            showSetupMenu(false);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showUserMenu(false);
            showGrayOut(false);
        }
    });

    // Documentation menu drop down
    $("#navbar_documentations_menu").click(function (e) {
        e.preventDefault();
        if ($("#navbar_documentation_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showEquipmentMenu(false);
            showUserMenu(false);
            showSetupMenu(false);
            showDocumentationMenu(true);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showDocumentationMenu(false);
            showGrayOut(false);
        }
    });

    // change Locale drop down
    $("#navbar_language_select_menu").click(function (e) {
        e.preventDefault();
        if ($("#navbar_language_select_dropdown").hasClass("hidden")) {
            // menu is hidden and will rise up
            showEquipmentMenu(false);
            showLocaleMenu(true);
            showUserMenu(false);
            showSetupMenu(false);
            showDocumentationMenu(false);
            showGrayOut(true);
        } else {
            // menu is shown and now hide it
            showLocaleMenu(false);
            showUserMenu(false);
            showGrayOut(false);
        }
    });

    // grayout
    $("#window_grayout").click(function (e) {
        e.preventDefault();
        showEquipmentMenu(false);
        showProductMenu(false);
        showManagementMenu(false);
        showAnalyticsMenu(false);
        showUserMenu(false);
        showSetupMenu(false);
        showGrayOut(false);
        showLocaleMenu(false);
        showSelectorsMenu(false);
        showDocumentationMenu(false);
    });


});