$(document).ready(function () {


    // --------------  User API activities Index page ---------------
    var user_api_activities_index_page = document.querySelector('#user_api_activities_grid');

    if (user_api_activities_index_page != undefined) {

        var user_api_activities_dictionary = {
            ru: {
                created_at: 'Дата создания',
                user_email: 'email',
                activity_type_name: 'Тип',
                equipment_model: 'Модель',
                equipment_category_name: 'Категория',
                equipment_category_group_name: 'Группа',
                product: 'Продукт',
                remote_ip: 'IP',
                server_name: 'API сервер',
            },
            en: {
                created_at: 'Created at',
                user_email: 'email',
                activity_type_name: 'Type',
                equipment_model: 'Model',
                equipment_category_name: 'Category',
                equipment_category_group_name: 'Group',
                product: 'Product',
                remote_ip: 'IP',
                server_name: 'API server',
            }
        };

        function getUserApiActivitiesColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return user_api_activities_dictionary[culture][word]
        }

        function resize_window_of_user_api_activities() {
            // $("#user_api_activities_grid").children(".k-grid-content").height($(window).height() - 260);

            $("#user_api_activities_grid").children(".k-grid-content").height(400);
        }

        $(window).resize(function () {
            resize_window_of_user_api_activities();
        });


        $("#user_api_activities_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: "/statistics_process/user_api_activities.json"
                },
                // group: [{
                //     title: 'Organization',
                //     field: "organization_name",
                //     dir: "asc",
                // }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            user_email: {type: "string"},
                            activity_type_name: {type: "string"},
                            equipment_model: {type: "string"},
                            equipment_category_name: {type: "string"},
                            equipment_category_group_name: {type: "string"},
                            product: {type: "string"},
                            remote_ip: {type: "string"},
                            created_at: {type: "date"},
                            organization_name: {type: "string"},
                            server_name: {type: "string"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_user_api_activities();
            },
            sortable: true,
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // navigatable: true,
            // selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/statistics_process/user_api_activities/#=id#">Show</i></a></div>',
                    width: 50
                },
                {
                    title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 150
                },
                {

                    title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'organization_name'),
                    field: "organization_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 200
                },
                {
                    title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'user_email'),
                    field: "user_email",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 200
                },
                {
                    title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'activity_type_name'),
                    field: "activity_type_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 150
                },
                // {
                //     title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'equipment_category_group_name'),
                //     field: "equipment_category_group_name",
                //     filterable: false,
                //     width: 150
                // },
                // {
                //     title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'equipment_category_name'),
                //     field: "equipment_category_name",
                //     filterable: false,
                //     width: 150
                // },
                // {
                //     title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'equipment_model'),
                //     field: "equipment_model",
                //     filterable: false,
                // },
                // {
                //     title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'product'),
                //     field: "product",
                //     filterable: false,
                // },
                {
                    title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'ip'),
                    field: "remote_ip",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 100
                },
                {
                    title: getUserApiActivitiesColumnNameByCulture(gon.locale, 'server_name'),
                    field: "server_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 100
                }

            ]
        });

    }

});