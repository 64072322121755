$(document).ready(function () {

    // --------------  Product Edit page ---------------
    var product_analytics_page = document.querySelector('#product_analytics_page');

    if (product_analytics_page != undefined) {


        // Highcharts.chart('component_types_group', {
        //     chart: {
        //         type: 'bar'
        //     },
        //     title: {
        //         text: 'Component types'
        //     },
        //     subtitle: {
        //         text: 'Number of component types mathing this product'
        //     },
        //     xAxis: {
        //         categories: ['Types'],
        //         title: {
        //             text: null
        //         }
        //     },
        //     yAxis: {
        //         min: 0,
        //         title: {
        //             text: 'Number of components',
        //             align: 'high'
        //         },
        //         labels: {
        //             overflow: 'justify'
        //         }
        //     },
        //     tooltip: {
        //         valueSuffix: 'units'
        //     },
        //     plotOptions: {
        //         bar: {
        //             dataLabels: {
        //                 enabled: true
        //             }
        //         }
        //     },
        //     // legend: {
        //     //     layout: 'vertical',
        //     //     align: 'right',
        //     //     verticalAlign: 'top',
        //     //     x: -40,
        //     //     y: 80,
        //     //     floating: true,
        //     //     borderWidth: 1,
        //     //     backgroundColor:
        //     //         Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        //     //     shadow: true
        //     // },
        //     credits: {
        //         enabled: false
        //     },
        //     // series: [{
        //     //     name: 'Year 1800',
        //     //     data: [107, 31, 635, 203, 2]
        //     // }, {
        //     //     name: 'Year 1900',
        //     //     data: [133, 156, 947, 408, 6]
        //     // }, {
        //     //     name: 'Year 2000',
        //     //     data: [814, 841, 3714, 727, 31]
        //     // }, {
        //     //     name: 'Year 2016',
        //     //     data: [1216, 1001, 4436, 738, 40]
        //     // }]
        //     series: gon.component_types_groups
        //
        // });


        Highcharts.chart('component_types_group', {
            chart: {
                type: 'column',
                zoomType: 'x',
            },
            title: {
                text: 'Component types'
            },
            subtitle: {
                text: `Number of component types match this product`
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: 'Number of components match'
                }

            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    }
                }
            },

            // tooltip: {
            //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            // },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "Component category",
                    colorByPoint: true,
                    data: gon.component_types_groups
                }
            ],

        });



        Highcharts.chart('equipment_category_group', {
            chart: {
                type: 'column',
                zoomType: 'x',
            },
            title: {
                text: 'Equipment category'
            },
            subtitle: {
                text: `Number of equipment category match this product`
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: 'Number of equipment category match'
                }

            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    }
                }
            },

            // tooltip: {
            //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            // },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "Equipment category",
                    colorByPoint: true,
                    data: gon.equipment_category_groups
                }
            ],

        });

        // Equipment manufacturers match
        Highcharts.chart('equipment_manufacturers_group', {
            chart: {
                type: 'column',
                zoomType: 'x',
            },
            title: {
                text: 'Equipment manufacturers'
            },
            subtitle: {
                text: `Number of equipment manufacturers match this product`
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: 'Number of equipment manufacturers match'
                }

            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    }
                }
            },

            // tooltip: {
            //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            // },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "Equipment manufacturers",
                    colorByPoint: true,
                    data: gon.equipment_manufacturers_groups
                }
            ],

        });

    }

});