$(document).ready(function () {


    // --------------  Manufacturerss Edit page ---------------
    var manufacturers_edit_page = document.querySelector('#series_edit_page');

    if (manufacturers_edit_page != undefined) {

        $("#series_manufacturer_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.series_manufacturer_name_en,
                id: gon.series_manufacturer_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/manufacturers.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });


    }

});