$(document).ready(function () {

    // --------------  Statistics visits graph ---------------
    var statistics_equipment_manufacturers_shown_graph = document.querySelector('#statistics_equipment_models_shown');

    if (statistics_equipment_manufacturers_shown_graph != undefined) {

        var selected_organization_id = gon.user_organization_id;

        // get first and last day of the current month
        const now = new Date();
        var firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        var lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        var date_from_format_text = ''
        var date_to_format_text = ''

        // Get activity type id to query results
        let chart_id = 'statistics_equipment_models_shown';
        let data_activity_type_id = $('#' + chart_id).attr('data-activity-type-id');
        var organization_params = 'date_from=' + gon.date_from + '&date_to=' + gon.date_to + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();


        var options_statistics_equipment_models_shown = {
            chart: {
                // type: 'column',
                type: 'bar',
                height: 1700,
                zooming: {
                    mouseWheel: false,
                }
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: [],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true
                    },
                },
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{}],
            colors: ['#ffab66', '#fd9a49', '#ff801e', '#c75f0f', '#a64d04'],
            credits: {
                enabled: false
            },
            exporting: {
                sourceWidth: 1280,
                sourceHeight: 1700,
                // scale: 2 (default)
                // chartOptions: {
                //     subtitle: null
                // }
            },
        }
        window.options_statistic_equipment_models_shown = options_statistics_equipment_models_shown;

        var chart = Highcharts.chart(chart_id, options_statistics_equipment_models_shown);
        chart.showLoading(gon.loading_data + '...');
        set_chart_height(chart);

        Highcharts.ajax({
            url: '/statistics_process/statistics_equipment_models_shown.json?' + organization_params,
            success: function (data) {
                data = jQuery.parseJSON(data);
                // Статистика подбора техники по модели
                options_statistics_equipment_models_shown.title.text = gon.number_of_rebounds_by_vehicle_brand + ' "' + data.organization_name + '"';
                options_statistics_equipment_models_shown.series[0].name = gon.number_of_rebounds_by_vehicle_brand;
                options_statistics_equipment_models_shown.series[0].data = data.equipment_model_shown_values;
                options_statistics_equipment_models_shown.xAxis.categories = data.equipment_model_shown_keys;
                options_statistics_equipment_models_shown.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();
                options_statistics_equipment_models_shown.yAxis.title.text = gon.number_of_rebounds;
                let chart = Highcharts.chart(chart_id, options_statistics_equipment_models_shown);
                chart.hideLoading();
                set_chart_height(chart);

                // console.log(data.equipment_model_shown_keys);
                // console.log(data.equipment_model_shown_values);
            }
        });

    }
})


