$(document).ready(function () {

    // --------------  Product manufacturers Index page ---------------
    var product_manufacturers_index_page = document.querySelector('#product_manufacturers_grid');

    if (product_manufacturers_index_page != undefined) {

        var product_manufacturers_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name_en: 'Производитель EN',
                name_ru: 'Производитель RU',
                organization_name: "Организация",
                products_count: "Продуктов",
                active_products_count: "Активных продуктов",
                is_active: "Активен",
                priority: "Приоритет",
                is_oem: "OEM",
            },
            en: {
                created_at: 'Created at',
                name_en: 'Manufacturer EN',
                name_ru: 'Manufacturer RU',
                organization_name: "Organization",
                products_count: "Products",
                active_products_count: "Active products",
                is_active: "Is active",
                priority: "Priority",
                is_oem: "OEM",
            }
        };

        function getProductManufacturersColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return product_manufacturers_dictionary[culture][word]
        }

        function resize_window_of_product_manufacturers() {
            $("#product_manufacturers_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window_of_product_manufacturers();
        });

        function product_manufacturers_table_row_coloring() {
            var grid = $("#product_manufacturers_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ddecd5");
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f6b8c3");
                }

            });
        }

        $("#product_manufacturers_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/product_manufacturers.json"
                },
                group: [{
                    title: 'organization_name',
                    field: "organization_name",
                    dir: "asc",
                }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                            organization_name: {type: "string"},
                            products_count: {type: "string"},
                            active_products_count: {type: "string"},
                            is_active: {type: 'boolean'},
                            priority: {type: "integer"},
                            is_oem: {type: "boolean"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_product_manufacturers();
                product_manufacturers_table_row_coloring();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-green-600 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/product_manufacturers/#=id#.xlsx"><i class="fa-solid fa-file-excel"></i></i></a></div>',
                    width: 40
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/product_manufacturers/#=id#/">Show</i></a></div>',
                    width: 60
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/product_manufacturers/#=id#/edit">Edit</i></a></div>',
                    width: 60
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 180
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'organization_name'),  //"Название организации",
                    field: "organization_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'name_en'),  //"Название производителя",
                    field: "name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'name_ru'),  //"Название производителя",
                    field: "name_ru",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'products_count'),  //"Название производителя",
                    field: "products_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 100
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'active_products_count'),  //"Название производителя",
                    field: "active_products_count",
                    // template: "<span color='#ffffff'>#= active_products_count #</span>",
                    template: "<div class='text-green-600'>#: active_products_count #</div>",

                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 100
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'is_active'),  //"Название отчета",
                    field: "is_active",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_active) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 100
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'is_oem'),  //"Название отчета",
                    field: "is_oem",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_oem) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 100
                },
                {
                    title: getProductManufacturersColumnNameByCulture(gon.locale, 'priority'),
                    template: "<div class='text-blue-600 font-bold'>#: priority #</div>",
                    field: "priority",
                    filterable: false,
                    sortable: true,
                    width: 100
                },



            ]
        });

    }

});