// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import { Turbo } from "@hotwired/turbo-rails"

Turbo.session.drive = false

// Turbo.setConfirmMethod(() => {
//     let dialog = document.getElementById("turbo-confirm");
//     dialog.showModal();
//
//     return new Promise((resolve, reject) => {
//         dialog.addEventListener("close", () => {
//             resolve(dialog.returnValue == "confirm")
//         }, {once: true})
//     })
// })

import "./controllers"

import "./src/jquery"
import "./src/jquery-ui.min"
import "jquery-ujs"
import "@nathanvda/cocoon"

import "./src/pako_deflate.min"
import "./src/kendo.all.min"
import "./src/messages/kendo.messages.en-US.min"
import "./src/messages/kendo.messages.ru-RU.min"

import "./src/import_highcharts"

// import "highcharts"

import './src/fontawesome/all'


import "./controllers/categories"
import "./controllers/change_intervals"
import "./controllers/manufacturers"
import "./controllers/manufacturers_show"
import "./controllers/manufacturers_edit"
import "./controllers/fuel_types"
import "./controllers/series"
import "./controllers/series_edit"
import "./controllers/product_groups"
import "./controllers/equipment"
import "./controllers/equipment_edit"
import "./controllers/equipment_edit_image_manage"

import "./controllers/equipment_show"
import "./controllers/component_types"
import "./controllers/lubricant_codes"
import "./controllers/lubricant_codes_show_products"
import "./controllers/lubricant_codes_show_components"
import "./controllers/lubricant_codes_edit"
import "./controllers/lubricant_code_types"
import "./controllers/operating_regimes"
import "./controllers/navbar"
import "./controllers/equipment_components"
import "./controllers/equipment_components_edit"
import "./controllers/equipment_components_change_conditions_edit"
import "./controllers/products"
import "./controllers/products_analytics"
import "./controllers/product_edit"
import "./controllers/product_manufacturers"
import "./controllers/product_manufacturer_show"
import "./controllers/product_manufacturer_edit"
import "./controllers/oil_viscosities"
import "./controllers/drive_types"
import "./controllers/users"
import "./controllers/users_edit"
import "./controllers/regions"

import "./controllers/selectors"
import "./controllers/selectors_show"
import "./controllers/code_selectors"
import "./controllers/selector2"

import "./controllers/organization"
import "./controllers/organization_edit"

import "./controllers/user_api_activities"
import "./controllers/user_api_activities_graph"


import "./controllers/statistics"
import "./controllers/statistics_edit"

import "./controllers/statistics_show_page"
import "./controllers/statistics_equipment_visits"
import "./controllers/statistics_equipment_products_shown"
import "./controllers/statistics_equipment_manufacturers_shown"
import "./controllers/statistics_equipment_models_shown"

import "./controllers/statistics_crossing_visits"
import "./controllers/statistics_crossing_products_shown"

import "./controllers/index_controller"

import "./controllers/analytics/reverse_crossing_selectors"
import "./controllers/analytics/reverse_crossing_products"
import "./controllers/analytics/product_compares"

import "./controllers/analytics/product_libraries"

import "./controllers/tools/merge_industrial_codes"

import "./controllers/age_filters"
import "./controllers/age_filters_edit"

import "./controllers/documentation"

import "./controllers/user_api_access_controls"

// import "./controllers/analytics"

// $(function () {
//     // console.log("hello world");
//
//     $('#dialog').dialog();
// })

import "trix"
import "@rails/actiontext"

// set auto height for Highcrart
function set_chart_height(chart) {
    let chart_id = '#' + $(chart.renderTo).attr('id');

    // Set auto height
    let pointPadding = 5; //Change to see correct results.
    let pointWidth = 10; //Change to see correct results.
    let heightOffset = 55; //Guestimate
    let w = $(chart_id).width(),
        h = $(chart_id).height(),
        box = chart.plotBox,
        len = chart.series[0].data.length;

    // fix if rows count is too small
    if (len <= 4) {
        len = 10;
    }

    chart.setSize(w, (len * (pointWidth + (pointPadding * 2))) + heightOffset);
    chart.hasUserSize = null;
}

window.set_chart_height = set_chart_height;
