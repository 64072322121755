$(document).ready(function () {

    // --------------  Statistics visits graph ---------------
    var statistics_crossing_visits_graph = document.querySelector('#statistics_crossing_visits');

    if (statistics_crossing_visits_graph != undefined) {
        var selected_organization_id = gon.user_organization_id;

        // get first and last day of the current month
        const now = new Date();
        // var firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        // var lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        let firstDay = new Date();
        let lastDay = new Date(new Date().setDate(firstDay.getDate() - 30));
        var date_from_format_text = ''
        var date_to_format_text = ''

        if ((gon.date_from == undefined) || (gon.date_to == undefined)) {
            gon.date_from = firstDay;
            gon.date_to = lastDay;
        }

        let chart_id = 'statistics_crossing_visits'
        let data_activity_type_id = $('#' + chart_id).attr('data-activity-type-id');
        var organization_params = 'date_from=' + gon.date_from + '&date_to=' + gon.date_to + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();

        // Статистика количества подборов техники
        var options_statistic_visits = {
            chart: {
                // type: 'spline',
                type: 'column',
                zooming: {
                    mouseWheel: false,
                }
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: 'Date'
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                min: 0
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x:%e. %b}: {point.y:.0f}'
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true
                    },
                    marker: {
                        enabled: true,
                        radius: 2.5
                    }
                }
            },
            // colors: ['#6CF', '#39F', '#06C', '#036', '#000']
            colors: ['#ffab66', '#fd9a49', '#ff801e', '#c75f0f', '#a64d04'],
            series: [{}],
            credits: {
                enabled: false
            },
            exporting: {
                sourceWidth: 1280,
                sourceHeight: 800,
                // scale: 2 (default)
                // chartOptions: {
                //     subtitle: null
                // }
            },
        };
        window.options_statistic_visits = options_statistic_visits;
        Highcharts.ajax({
            url: '/statistics_process/statistics_crossing_visits.json?' + organization_params,
            success: function (data) {
                data = jQuery.parseJSON(data);
                options_statistic_visits.title.text = gon.number_of_crossing_match + ' "' + data.organization_name + '"';
                options_statistic_visits.series[0].name = gon.date_of_request;
                options_statistic_visits.series[0].data = data.user_activity;
                options_statistic_visits.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();
                options_statistic_visits.yAxis.title.text = gon.number_of_requests;

                Highcharts.chart(chart_id, options_statistic_visits);
            }
        });

    }

});

