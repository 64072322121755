$(document).ready(function () {


    // --------------  Change intervals Index page ---------------
    var change_intervals_index_page = document.querySelector('#change_intervals_grid');

    if (change_intervals_index_page != undefined) {

        var change_intervals_dictionary = {
            ru: {
                created_at: 'Дата создания',
                interval: 'Интервал',
                change_interval_unit_name_en: 'Размерность',
            },
            en: {
                created_at: 'Created at',
                interval: 'Interval',
                change_interval_unit_name_en: 'Units',
            }
        };

        function getChangeIntervalsColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return change_intervals_dictionary[culture][word]
        }

        function resize_window_of_change_intervals() {
            $("#change_intervals_grid").children(".k-grid-content").height($(window).height() - 260);
        }

        $(window).resize(function () {
            resize_window_of_change_intervals();
        });


        $("#change_intervals_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/change_intervals.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            interval: {type: "string"},
                            change_interval_unit_name_en: {type: "string"},
                            equipment_component_id: {type: "integer"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_change_intervals();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
//            navigatable: true,
//            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/change_intervals/#=id#">Show</i></a></div>',
                    width: 50
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/change_intervals/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                {
                    title: getChangeIntervalsColumnNameByCulture(gon.locale, 'interval'),  //"Название отчета",
                    field: "interval",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getChangeIntervalsColumnNameByCulture(gon.locale, 'change_interval_unit_name_en'),  //"Название отчета",
                    field: "change_interval_unit_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
            ]
        });

    }

});