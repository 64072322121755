var selectors_category_group_id = null;
var selectors_manufacturer_id = null;
var selectors_equipment_id = null;

$(document).ready(function () {

    // --------------  Series Show page ---------------
    var series_show_page = document.querySelector('#selector_show_page');

    if (series_show_page != undefined) {

        $(".wrap img").hover(function () {
            kendo.fx(this).zoom("in").startValue(1).endValue(1.05).play();
        }, function () {
            kendo.fx(this).zoom("out").endValue(1).startValue(1.05).play();
        });

        $('[id^=component_type_header_]').click(function (event) {

            event.preventDefault();
            event.stopPropagation();

            // get id of clicked element
            var id = $(this).attr('id');

            // clean id and get number of element
            id = id.replace('component_type_header_', '');
            // console.log(id);

            // toggle components
            // var component = $('#components_' + id).toggleClass('hidden');

            var component = $('#components_' + id);

            if (component.is(":hidden")) {
                component.show("blind", {direction: "up"}, 250);
                $('.component_type_header_plus_minus_' + id).html('<i class="fa-regular fa-minus"></i>');
            } else {
                component.hide("blind", {direction: "up"}, 250);
                $('.component_type_header_plus_minus_' + id).html('<i class="fa-regular fa-plus"></i>');
            }

            // toggle image +/-
            // if (component.hasClass("hidden")) {
            //     $('.component_type_header_plus_minus_' + id).html('<i class="fa-regular fa-plus"></i>');
            // } else {
            //     $('.component_type_header_plus_minus_' + id).html('<i class="fa-regular fa-minus"></i>');
            // }

            return false;
        });

        var a = $('#components_1');

        a.click();

    }

});