$(document).ready(function () {

    // --------------  Users Edit page ---------------
    var age_filter_edit_page = document.querySelector('#age_filter_edit');

    if (age_filter_edit_page != undefined) {


        $("#age_filter_organization_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "short_w_opf_name",
            dataValueField: "id",
            optionLabel: {
                id: gon.product_manufacturer_organization_id,
                short_w_opf_name: gon.product_manufacturer_organization_short_w_opf_name,
            },
            template: '<span class="k-state-default"><h3>#: data.short_w_opf_name #</h3><p>Контракт: #: data.contract_number #</p> <p>ИНН: #: data.inn #</p>  </span>',
            dataSource: {
                serverFiltering: true,
                // sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        dataType: "json",
                        url: "/organizations.json",
                    },
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            short_w_opf_name: {type: "string"},
                            contract_number: {type: "string"},
                            inn: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#age_filter_component_type_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            // autoBind: false,
            optionLabel: {
                id: gon.equipment_component_type_id,
                name_en: gon.equipment_component_type_name,
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/component_types.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#age_filter_category_ids").kendoMultiSelect({
            placeholder: "Please Select a Category",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.categories,
            autoClose: false,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/categories.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                }
            }
        });


        // ----------- Show Modal copy product manufacturer -----------
        $("#age_filter_copy_to_organization_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "short_w_opf_name",
            dataValueField: "id",
            // optionLabel: {
            //     id: gon.age_filter_organization_id,
            //     name_en: gon.age_filter_organization_short_w_opf_name,
            // },
            template: '<span class="k-state-default"><h3>#: data.short_w_opf_name #</h3><p>Контракт: #: data.contract_number #</p> <p>ИНН: #: data.inn #</p>  </span>',
            dataSource: {
                serverFiltering: true,
                // sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        dataType: "json",
                        url: "/organizations.json",
                    },
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            short_w_opf_name: {type: "string"},
                            contract_number: {type: "string"},
                            inn: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#age_filter_copy_button").click(function (e) {
            e.preventDefault();
            $('#modal_window_age_filter_copy').fadeTo(250, 1, function () {
                $(this).show()
            })
            $('#modal_window_age_filter_copy_overlay').fadeTo(250, 1, function () {
                $(this).show()
            })
            // $("#application_zebra_label_input_text").focus();
        });

        function hide_modal_overlay(e) {
            e.preventDefault();

            $('#modal_window_age_filter_copy_overlay').fadeTo(250, 0, function () {
                $(this).hide()
            })

            $('#modal_window_age_filter_copy').fadeTo(250, 0, function () {
                $(this).hide()
            })
        }

        $("#modal_window_age_filter_copy_cancel_button").click(function (e) {
            hide_modal_overlay(e);
        })

        $("#modal_window_age_filter_copy_grayout").click(function (e) {
            hide_modal_overlay(e);
        })

        function age_filter_check_is_copy() {
            $('#is_make_copy_of_age_filter').prop('checked', true);
            return $.when('set copy');
        }

        function age_filter_submit_update_form() {
            console.log('submit');
            $('#age_filter_form_save_button').trigger('click');
            return $.when('submit form');
        }

        $("#modal_window_age_filter_make_copy_button").click(function (e) {
            e.preventDefault();
            console.log('click');
            age_filter_check_is_copy().then(age_filter_submit_update_form());

        });


    }

});