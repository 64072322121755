$(document).ready(function () {


    // --------------  Component Types Index page ---------------
    var component_types_index_page = document.querySelector('#component_types_grid');

    if (component_types_index_page != undefined) {

        var component_types_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name_en: 'Тип en',
                name_ru: 'Тип ru',
                priority: 'Приоритет',
                equipment_components_count: 'Кол-во компонентов'
            },
            en: {
                created_at: 'Created at',
                name_en: 'Type en',
                name_ru: 'Type ru',
                priority: 'Priority',
                equipment_components_count: 'Count of components'
            }
        };

        function getComponentTypesColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return component_types_dictionary[culture][word]
        }

        function resize_window_of_component_types() {
            $("#component_types_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window_of_component_types();
        });


        $("#component_types_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/component_types.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                            priority: {type: "string"},
                            equipment_components_count: {type: "string"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_component_types();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
//            navigatable: true,
//            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/component_types/#=id#/">Show</i></a></div>',
                    width: 60
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/component_types/#=id#/edit">Edit</i></a></div>',
                    width: 60
                },
                {
                    title: getComponentTypesColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 180
                },
                {
                    title: getComponentTypesColumnNameByCulture(gon.locale, 'name_en'),  //"Название кода en",
                    field: "name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getComponentTypesColumnNameByCulture(gon.locale, 'name_ru'),  //"Название кода en",
                    field: "name_ru",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getComponentTypesColumnNameByCulture(gon.locale, 'priority'),  //"Название кода en",
                    field: "priority",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getComponentTypesColumnNameByCulture(gon.locale, 'equipment_components_count'),  //"Название кода en",
                    field: "equipment_components_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
            ]
        });

    }

});