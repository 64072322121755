$(document).ready(function () {


    // --------------  Lubricant Codes Edit page ---------------
    var lubricant_codes_edit_page = document.querySelector('#lubricant_code_lubricant_code_type_id');

    if (lubricant_codes_edit_page != undefined) {

        $("#lubricant_code_lubricant_code_type_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.lubricant_code_type_name_en,
                id: gon.lubricant_code_type_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/lubricant_code_types.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#lubricant_code_lubricant_code_standard_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.lubricant_code_standard_name,
                id: gon.lubricant_code_standard_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/lubricant_code_standards.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#lubricant_code_back_compatibility_ids").kendoMultiSelect({
            placeholder: "Please Select backward compatibility codes",
            dataTextField: "industry_reference",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.back_compatibility_ids,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/lubricant_codes.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            industry_reference: {type: "string"},
                        }
                    }
                }
            }
        });

        $("#lubricant_code_analogue_ids").kendoMultiSelect({
            placeholder: "Please Select analogue codes",
            dataTextField: "industry_reference",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.analogue_ids,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/lubricant_codes.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            industry_reference: {type: "string"},
                        }
                    }
                }
            }
        });

    }

});