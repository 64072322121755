$(document).ready(function () {

    // --------------  Equipment Components Edit Page ---------------
    var equipment_components_edit_page = document.querySelector('#equipment_components_page');

    if (equipment_components_edit_page != undefined) {

        /////////////  Component Change conditions  /////////////

        let lubricant_codes_datasource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            sort: {field: "industry_reference", dir: "asc"},
            transport: {
                read: {
                    url: "/lubricant_codes.json",
                    data: {
                        filter: {
                            logic: "and",
                            filters: [
                                {field: "paging_enable", operator: "eq", value: true},
                                {field: "is_active", operator: "eq", value: true}
                            ]
                        }
                    }
                }
            },
            schema: {
                total: "total",
                data: "results",
                id: "id",
                model: {
                    fields: {
                        id: {type: "integer"},
                        industry_reference: {type: "string"}
                    }
                }
            }
        });
        // lubricant_codes_datasource.read();


        // Assign on page load to all elements of Oil viscosities
        $("[id ^=equipment_component_component_change_conditions_attributes_][id $=_oil_viscosity_ids]").kendoMultiSelect({
            filter: "startswith",
            placeholder: "Please select a Viscosities",
            dataTextField: "name",
            dataValueField: "id",
            autoBind: true,
            height: 500,
            dataSource: gon.viscosities_data,
        });
        $("[id ^=equipment_component_component_change_conditions_attributes_][id $=_oil_viscosity_ids]").removeClass('assign_lubricant_code_component_change_condition');


        $('[id ^=equipment_component_component_change_conditions_attributes_][id $=_lubricant_code_ids]').each(function (index) {

            // console.log($(this).attr('data_changecondition_id'));

            // $(this)

            $(this).kendoMultiSelect({
                filter: "contains",
                placeholder: "Please select a industry references",
                dataTextField: "industry_reference",
                dataValueField: "id",
                // valuePrimitive: true,
                autoBind: false,
                height: 500,
                // value: [
                //     { industry_reference: "JATCO Automatic Transmission Fluid N402", id: 247 },
                //     { industry_reference: "ZF 5HP18EL", id: 3162 }
                // ],
                value: gon.lubricant_codes_by_initial_capacities[$(this).attr('data_changecondition_id')],
                // dataSource: gon.lubricant_codes_data,
                // dataSource: lubricant_codes_datasource,
                // dataBound: function (e) {
                //     var dataItem = this.dataItem(e.item);
                // console.log(this);
                //
                // }
                dataSource: {
                    serverFiltering: true,
                    // serverSorting: true,
                    sort: {field: "industry_reference", dir: "asc"},
                    transport: {
                        read: {
                            url: "/lubricant_codes.json",
                            data: {
                                filter: {
                                    logic: "and",
                                    filters: [
                                        {field: "paging_enable", operator: "eq", value: true},
                                        {field: "is_active", operator: "eq", value: true}
                                    ]
                                }
                            }
                        }
                    },
                    schema: {
                        total: "total",
                        data: "results",
                        id: "id",
                        model: {
                            fields: {
                                id: {type: "integer"},
                                industry_reference: {type: "string"}
                            }
                        }
                    }
                }

            });
            $(this).removeClass('assign_lubricant_code');

        });

        // Assign on page load to all elements of Lubricant codes
        // $('[id ^=equipment_component_component_change_conditions_attributes_][id $=_lubricant_code_ids]').kendoMultiSelect({
        //     filter: "contains",
        //     placeholder: "Please select a industry references",
        //     dataTextField: "industry_reference",
        //     dataValueField: "id",
        //     // valuePrimitive: true,
        //     autoBind: false,
        //     height: 500,
        //     // value: [
        //     //     { industry_reference: "JATCO Automatic Transmission Fluid N402", id: 247 },
        //     //     { industry_reference: "ZF 5HP18EL", id: 3162 }
        //     // ],
        //     // value: gon.lubricant_codes_by_initial_capacities[0],
        //     // dataSource: gon.lubricant_codes_data,
        //     // dataSource: lubricant_codes_datasource,
        //     // dataBound: function (e) {
        //     //     var dataItem = this.dataItem(e.item);
        //         // console.log(this);
        //     //
        //     // }
        //     dataSource: {
        //         serverFiltering: true,
        //         // serverSorting: true,
        //         sort: {field: "industry_reference", dir: "asc"},
        //         transport: {
        //             read: {
        //                 url: "/lubricant_codes.json",
        //                 data: {
        //                     filter: {
        //                         logic: "and",
        //                         filters: [
        //                             {field: "paging_enable", operator: "eq", value: true},
        //                             {field: "is_active", operator: "eq", value: true}
        //                         ]
        //                     }
        //                 }
        //             }
        //         },
        //         schema: {
        //             total: "total",
        //             data: "results",
        //             id: "id",
        //             model: {
        //                 fields: {
        //                     id: {type: "integer"},
        //                     industry_reference: {type: "string"}
        //                 }
        //             }
        //         }
        //     }
        //
        // });
        // $("[id ^=equipment_component_component_change_conditions_attributes_][id $=_lubricant_code_ids]").removeClass('assign_lubricant_code');


        // Assign on page load to all elements of Regions
        $('[id ^=equipment_component_component_change_conditions_attributes_][id $=_region_ids]').kendoMultiSelect({
            filter: "startswith",
            placeholder: "Please select a region",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: true,
            height: 500,
            dataSource: gon.regions_data,

        });
        $("[id ^=equipment_component_component_change_conditions_attributes_][id $=_region_ids]").removeClass('assign_region_component_change_condition');


        // Assign on page load to all elements of Product manufacturers
        $('[id ^=equipment_component_component_change_conditions_attributes_][id $=_product_manufacturer]').kendoDropDownList({
            filter: "startswith",
            placeholder: "Product manufacturer",
            // dataTextField: "name_en",
            // dataValueField: "id",
            autoBind: true,
            height: 500,
            dataSource: gon.product_manufacturer_names,

        });
        $("[id ^=equipment_component_component_change_conditions_attributes_][id $=_product_manufacturer]").removeClass('assign_product_manufacturer_component_change_condition');


        $(document).on('click', '#add_component_change_condition', function (e) {
            $('.assign_lubricant_code_component_change_condition').each(function (index) {
                $(this).removeClass('assign_lubricant_code_component_change_condition');

                $(this).kendoMultiSelect({
                    filter: "contains",
                    placeholder: "Please select a industry references",
                    dataTextField: "industry_reference",
                    dataValueField: "id",
                    // valuePrimitive: true,
                    autoBind: false,
                    height: 500,
                    dataSource: gon.lubricant_codes_data,
                    // dataSource: lubricant_codes_datasource
                });
            });

            $('.assign_viscosity_component_change_condition').each(function (index) {

                $(this).removeClass('assign_viscosity_component_change_condition');

                $(this).kendoMultiSelect({
                    filter: "contains",
                    placeholder: "Please select a viscosities",
                    dataTextField: "name",
                    dataValueField: "id",
                    autoBind: true,
                    height: 500,
                    dataSource: gon.viscosities_data,
                })
            });

            $('.assign_region_component_change_condition').each(function (index) {
                $(this).removeClass('assign_region_component_change_condition');

                $(this).kendoMultiSelect({
                    filter: "contains",
                    placeholder: "Please select a region",
                    dataTextField: "name_en",
                    dataValueField: "id",
                    autoBind: true,
                    height: 500,
                    dataSource: gon.regions_data,
                })
            });

            $('.assign_product_manufacturer_component_change_condition').each(function (index) {
                $(this).removeClass('assign_product_manufacturer_component_change_condition');

                $(this).kendoDropDownList({
                    filter: "startswith",
                    placeholder: "Product manufacturer",
                    // dataTextField: "name_en",
                    // dataValueField: "id",
                    autoBind: true,
                    height: 500,
                    dataSource: gon.product_manufacturer_names,
                })
            });


        });

        // On add interval
        $(document).on('click', '.add_component_change_condition_interval_button', function (e) {

            $('.assign_region_component_change_condition').each(function (index) {
                $(this).removeClass('assign_region_component_change_condition');

                $(this).kendoMultiSelect({
                    filter: "contains",
                    placeholder: "Please select a region",
                    dataTextField: "name_en",
                    dataValueField: "id",
                    autoBind: true,
                    height: 500,
                    dataSource: gon.regions_data,
                })
            });

        });

    }

});

