$(document).ready(function () {

    // --------------  Products Index page ---------------
    var reverse_crossing_products_index_page = document.querySelector('#reverse_crossing_products_grid');

    if (reverse_crossing_products_index_page != undefined) {

        var products_dictionary = {
            ru: {
                created_at: 'Дата создания',
                product_manufacturer_name: 'Производитель',
                vendor_code: 'Код вендора',
                vendor_code2: 'Код вендора 2',
                name: 'Название',
                priority: 'Приоритет',
                product_image_attached: "Картинка продукта",
                organization_name: "Организация",
                is_active: "Активен",
                is_active_by_product_manufacturer: "Производитель активен",
                is_description_ru: "is ru",
                is_description_en: "is en",
                is_description_ja: "is ja",
                packagings_count: "Кол-во фасовок",
                oil_viscosities: "Вязкости",
                lubricant_codes: "Коды",
                matching_percent: "Совпадение %",
            },
            en: {
                created_at: 'Created at',
                product_manufacturer_name: 'Manufacturer',
                vendor_code: 'Vendor code',
                vendor_code2: 'Vendor code 2',
                name: 'Name',
                priority: 'Priority',
                product_image_attached: "Image of product",
                organization_name: "Organization",
                is_active: "Is active",
                is_active_by_product_manufacturer: "Manufacturer is active",
                is_description_ru: "is ru",
                is_description_en: "is en",
                is_description_ja: "is ja",
                packagings_count: "packagings count",
                oil_viscosities: "Oil viscosities",
                lubricant_codes: "Industry codes",
                matching_percent: "Matching %",
            }
        };

        function getProductsColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return products_dictionary[culture][word]
        }

        function resize_window_of_products() {
            $("#reverse_crossing_products_grid").children(".k-grid-content").height($(window).height() - 625);
        }

        $(window).resize(function () {
            resize_window_of_products();
        });

        function products_table_row_coloring() {
            var grid = $("#reverse_crossing_products_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ddecd5");
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f6b8c3");
                }

                if (row.is_active_by_product_manufacturer == false) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ffacbb");
                }

            });
        }

        // function analytics_compare_products(e) {
        //     e.preventDefault();
        //
        //     // Get mid from selected analogue product
        //     var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
        // }

        $("#reverse_crossing_products_grid").on("click", ".customEdit", function(event){
            // var row = $(this).closest("tr");
            // console.log(row);


            // var grid = $("#reverse_crossing_products_grid").data("kendoGrid");
            let row = reverse_crossing_products_grid.dataItem($(event.target).closest("tr"));

            // console.log(row.mid);
            // console.log(gon.original_mid);

            let params = 'original_mid=' + gon.original_mid;
            params += '&' + 'mid=' + row.mid;
            var win = window.open('/' + gon.locale + '/analytics/product_compares/compare?' + params, '_blank');
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                alert('Please allow popups for this website');
            }

        });

        let reverse_crossing_products_grid = $("#reverse_crossing_products_grid").kendoGrid({
            dataSource: {
                sort: [
                    {field: "matching_percent", dir: "desc"},
                    {field: "name", dir: "desc"},
                ],
                data: gon.analogue_products_data,
                schema: {
                    model: {
                        fields: {
                            mid: {type: "string"},
                            product_manufacturer_name: {type: "string"},
                            name: {type: "string"},
                            oil_viscosities: {type: "string"},
                            lubricant_codes: {type: "string"},
                            matching_percent: {type: "integer"},
                        }
                    }
                },
                pageSize: 30
            },
            dataBound: function (e) {
                resize_window_of_products();
                // products_table_row_coloring();
            },

            scrollable: true,
            sortable: {
                mode: "mixed",
                allowUnsort: true,
                showIndexes: true
            },
            // filterable: true,
            resizable: true,
            pageable: {
                input: true,
                numeric: false
            },
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><button class="customEdit inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-black bg-lubribase-orange-light hover:bg-lubribase-orange-btn hover:text-white" style="font-size: 11px;" ><i class="fa-duotone fa-code-compare"></i></button></div>',
                    width: 50
                },
                // {
                //     command: {
                //         template: '<button class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-black bg-lubribase-orange-light hover:bg-lubribase-orange-btn hover:text-white" style="font-size: 11px;" ><i class="fa-duotone fa-code-compare"></i></button>',
                //         // text: "Compare",
                //         click: analytics_compare_products
                //     },
                //     title: " ",
                //     width: 70
                // },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'product_manufacturer_name'),  //"Дата создания",
                    field: "product_manufacturer_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 150
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'name'),  //"Название организации",
                    field: "name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    // width: 120
                },
                // {
                //     title: getProductsColumnNameByCulture(gon.locale, 'oil_viscosities'),  //"Название отчета",
                //     field: "oil_viscosities",
                //     // template: '<div class="flex items-center justify-center"># if (data.is_active_by_product_manufacturer) {# <span class="text-green-500">#: data.product_manufacturer_name_en #</span>#} else { # <span class="text-red-500">#: data.product_manufacturer_name_en #</span> # } #</div>',
                //     filterable: {
                //         cell: {
                //             showOperators: false
                //         }
                //     },
                //     width: 120
                // },
                // {
                //     title: getProductsColumnNameByCulture(gon.locale, 'lubricant_codes'),  //
                //     field: "lubricant_codes",
                //     filterable: {
                //         filter: "contains",
                //         cell: {
                //             showOperators: false
                //         }
                //     }
                // },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'matching_percent'),  //
                    field: "matching_percent",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    filterable: false,
                    width: 150
                },
            ]
        }).data("kendoGrid");

    }

});