$(document).ready(function () {

    // --------------  Users Edit page ---------------
    var users_edit_page = document.querySelector('#user_is_api_available');

    if (users_edit_page != undefined) {

        $("#user_organization_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "short_w_opf_name",
            dataValueField: "id",
            optionLabel: {
                id: gon.user_organization_id,
                short_w_opf_name: gon.user_organization_short_w_opf_name,
            },
            template: '<span class="k-state-default"><h3>#: data.short_w_opf_name #</h3><p>Контракт: #: data.contract_number #</p> <p>ИНН: #: data.inn #</p>  </span>',
            dataSource: {
                serverFiltering: true,
                // sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        dataType: "json",
                        url: "/organizations.json",
                    },
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            short_w_opf_name: {type: "string"},
                            contract_number: {type: "string"},
                            inn: {type: "string"},
                        }
                    }
                },
            }
        });


        // var data = [
        //     {text: "Black", value: "1"},
        //     {text: "Orange", value: "2"},
        //     {text: "Grey", value: "3"}
        // ];
        //
        // $('[id^="user_permissions_attributes"][id $=_name]').kendoDropDownList({
        //     dataTextField: "text",
        //     dataValueField: "value",
        //     dataSource: data,
        //     index: 0,
        //     // change: onChange
        // });

        // Show Modal copy user permissions

        $("#user_copy_permissions_to_email").kendoDropDownList({
            filter: "startswith",
            dataTextField: "email",
            dataValueField: "id",
            // optionLabel: {
            //     id: gon.user_organization_id,
            //     name_en: gon.user_organization_short_w_opf_name,
            // },
            select: function (e) {
              console.log('select');
            },
            // template: '<span class="k-state-default"><h3>#: data.short_w_opf_name #</h3><p>Контракт: #: data.contract_number #</p> <p>ИНН: #: data.inn #</p>  </span>',
            dataSource: {
                serverFiltering: true,
                // sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        dataType: "json",
                        url: "/users.json",
                    },
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            email: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#user_copy_button").click(function (e) {
            e.preventDefault();
            $('#modal_window_user_copy').fadeTo(250, 1, function () {
                $(this).show()
            })
            $('#modal_window_user_copy_overlay').fadeTo(250, 1, function () {
                $(this).show()
            })
            // $("#application_zebra_label_input_text").focus();
        });

        function hide_modal_overlay(e) {
            e.preventDefault();

            $('#modal_window_user_copy_overlay').fadeTo(250, 0, function () {
                $(this).hide()
            })

            $('#modal_window_user_copy').fadeTo(250, 0, function () {
                $(this).hide()
            })
        }

        $("#modal_window_user_copy_cancel_button").click(function (e) {
            hide_modal_overlay(e);
        })

        $("#modal_window_user_copy_grayout").click(function (e) {
            hide_modal_overlay(e);
        })

        function user_check_is_copy() {
            $('#is_make_copy_of_permissions').prop('checked', true);
            return $.when('set copy');
        }

        function user_submit_update_form() {
            $('#user_form_save_button').trigger('click');
            return $.when('submit form');
        }

        $("#modal_window_user_make_copy_button").click(function (e) {
            e.preventDefault();

            user_check_is_copy().then(user_submit_update_form());
        });


        // Generate a password string
        function randString(id) {
            var dataSet = $(id).attr('data-character-set').split(',');
            var possible = '';
            if ($.inArray('a-z', dataSet) >= 0) {
                possible += 'abcdefghijklmnopqrstuvwxyz';
            }
            if ($.inArray('A-Z', dataSet) >= 0) {
                possible += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            }
            if ($.inArray('0-9', dataSet) >= 0) {
                possible += '0123456789';
            }
            if ($.inArray('#', dataSet) >= 0) {
                possible += '^#<>{}!$()~|';
            }
            var text = '';
            for (var i = 0; i < $(id).attr('data-size'); i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        }

        if (gon.generate_password) {
            // Create a new password on page load
            $('input[rel="gp"]').each(function () {
                $(this).val(randString($(this)));
            });
        }


        $('#generate_user_password').click(function (e) {
            e.preventDefault();

            // $('#user_password').val('asd');

            var field = $(this).closest('div').find('input[rel="gp"]');
            field.val(randString(field));

        });




    }

});