var equipment_manufacturer_id = null;

$(document).ready(function () {

    // --------------  Equipment Edit Page ---------------
    var equipment_edit_page = document.querySelector('#equipment_edit_page');

    if (equipment_edit_page != undefined) {

        // TODO temporary disable auto image search
        // if (!gon.is_equipment_images) {
        if (false) {
            show_found_images();
        } else {
            $('#search_images_block').addClass('hidden');
        }

        $(document).on('click', '#show_found_images_button', function (e) {
            $('#search_images_block').removeClass('hidden');
            show_found_images();
        });


        function show_found_images() {
            $.ajax({
                type: "GET",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))
                },
                url: "/" + gon.locale + "/equipment/" + gon.equipment_id + "/search_images.json",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                headers: {"Accept": "application/json"},
                // data: JSON.stringify(data),
                // async: false,
                timeout: 50000, // sets timeout to 30 seconds
                success: function (data) {

                    $('#search_images').html('');
                    let style_line = '';

                    // console.log(data);

                    $.each(data['results_data'], function (index, image_in_styles) {
                        // console.log(index);
                        // console.log(image_in_styles);

                        style_line = '<div class="flex flex-row space-x-2 space-y-2">';
                        style_line += '<div class="flex flex-row space-x-2 my-5"><div class="flex items-center"><button data-style="' + index.toString() + '" class="assign_images_button rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Attach to equipment</button></div>';

                        $.each(image_in_styles, function (index_of_style, style) {

                            // console.log(index_of_style);
                            // console.log(style);

                            style_line += '<div><img data-index="' + index.toString() + '" data-style="' + index_of_style.toString() + '" class="result_image_url h-[200px] product_img_link w-full object-scale-down object-center" src="' + style + '"></div>';

                            // console.log('#0-' + car_number.toString());
                            // console.log(style['result_image_url']);
                        });
                        style_line += '</div>';

                        $('#search_images').append(style_line);
                    });

                    $('[id ^=attach_images_button_]')

                    $(document).on('click', '.assign_images_button', function (e) {
                        let button_data_style = $(this).attr('data-style');
                        // console.log('AAAA', button_data_style);

                        // $('.result_image_url').each()

                        let images_array = [];
                        let image_hash = {};

                        $.each($('.result_image_url'), function (style_id, item) {

                            if ($(this).attr('data-index').toString() == button_data_style.toString()) {
                                // console.log(item);
                                // images_array.push($(this).attr('src'));

                                image_hash[$(this).attr('data-style')] = $(this).attr('src');
                            }
                        });

                        console.table(image_hash);
                        // attach_images_to_equipment(images_array);

                        let item = {}
                        item["images"] = image_hash;

                        attach_images_to_equipment(item);
                    });


                },
                error: function () {
                    console.log('error');
                    $('#search_images').html('Images not found');
                },


            });
        }


        $(document).on('click', '#search_images_repeat_button', function (e) {
            search_images_repeat();
        });


        function search_images_repeat() {

            $.ajax({
                type: "GET",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))
                },
                url: "/" + gon.locale + "/equipment/" + gon.equipment_id + "/search_images_repeat.json",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                headers: {"Accept": "application/json"},

                // async: false,
                timeout: 50000, // sets timeout to 30 seconds
                success: function (data) {
                    $('#search_images_repeat_button').addClass('hidden');
                    console.log('success');
                },
                error: function () {
                    console.log('error');
                },


            });

        }


        function attach_images_to_equipment(images_hash) {

            $.ajax({
                type: "POST",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))
                },
                url: "/" + gon.locale + "/equipment/" + gon.equipment_id + "/attach_images.json",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                headers: {"Accept": "application/json"},
                data: JSON.stringify(images_hash),
                // async: false,
                timeout: 50000, // sets timeout to 30 seconds
                success: function (data) {
                    console.log('success');
                    location.reload();
                },
                error: function () {
                    console.log('error');
                },


            });

        }


        $(document).on('click', '#apply_selected_image_to_styles_button', function (e) {
            apply_selected_image_to_styles();
        });

        function apply_selected_image_to_styles() {

            $.ajax({
                type: "GET",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))
                },
                // POST http://localhost/(:locale)/equipment/{{id}}/apply_selected_image_to_styles
                url: "/" + gon.locale + "/equipment/" + gon.equipment_id + "/apply_selected_image_to_styles.json",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                headers: {"Accept": "application/json"},
                // data: JSON.stringify(images_hash),
                // async: false,
                timeout: 50000, // sets timeout to 30 seconds
                success: function (data) {
                    console.log('success');
                    // location.reload();
                },
                error: function () {
                    console.log('error');
                },


            });

        }


    }

});