$(document).ready(function () {


    // --------------  Main Index page ---------------
    var main_index_page = document.querySelector('#index_page');


    if (main_index_page != undefined) {

        // console.log('asd');
        // $(window).resize(function () {
        //     var scrollView = $("#services_scroller").kendoScrollView;
        //     scrollView.refresh();
        //
        //     console.log('resize');
        // });

        // console.log('bbb');

        $("#services_scroller").kendoScrollView({
            dataSource: {
                // type: "json",
                transport: {
                    read: {
                        url: "/" + gon.locale + "/services.json",
                        // url: "https://demos.telerik.com/kendo-ui/service/Northwind.svc/Products"
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_ru: {type: "string"},
                            name_en: {type: "string"},
                            name: {type: "string"},
                            link: {type: "string"},
                            service_image_url: {type: "string"},
                            description_en: {type: "string"},
                            description_ru: {type: "string"},
                            description: {type: "string"},
                        }
                    }
                },
                serverPaging: true,
                pageSize: 2
            },
            template: $("#scrollview-template").html(),
            // change: set_hovers,
            // contentHeight: 900,
            contentHeight: "100%",
            // duration: "800",
            enablePager: true
        })

        // $(".tilled").hover(
        //     function (e) {
        //         var div = $(e.currentTarget);
        //         kendo.fx(div.find(".description").css("display", "block")).tile("left", div.find(".icon")).play();
        //
        //         console.log('hover !');
        //     },
        //
        //     function (e) {
        //         var div = $(e.currentTarget);
        //         kendo.fx(div.find(".description")).tile("left", div.find(".icon")).reverse();
        //
        //         console.log('hover !');
        //     });






    }

});