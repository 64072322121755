
$(document).ready(function () {

    // --------------  Equipment Components Edit Page ---------------
    var manufacturers_edit_page = document.querySelector('#manufacturer_region_ids');

    if (manufacturers_edit_page != undefined) {




    }

});