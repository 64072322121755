
$(document).ready(function () {

    // --------------  Lubricant show components Index page ---------------
    var lubricant_code_equipment_components_index_page = document.querySelector('#lubricant_code_equipment_components_grid');

    if (lubricant_code_equipment_components_index_page != undefined) {

        var lubricant_code_equipment_components_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name: 'Название',
                equipment_model: 'Оборудование',
                component_type_name: 'Тип',
                daterange: 'Даты производства',
                operating_regimes_name: 'Режим эксплуатации',
                drive_type_name: 'Тип привода',
                component_code: 'Код компоненты',
                change_intervals_s: 'Интервал смены',
                capacities: 'Заливка',
            },
            en: {
                created_at: 'Created at',
                name: 'Name',
                equipment_model: 'Equipment model',
                component_type_name: 'Type',
                daterange: 'Make date range',
                operating_regimes_name: 'Operating regime',
                drive_type_name: 'Drive type',
                component_code: 'Component code',
                change_intervals_s: 'Change interval',
                capacities: 'Capacities',
            }
        };

        function getEquipmentComponentsColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return lubricant_code_equipment_components_dictionary[culture][word]
        }


        function resize_window_of_lubricant_code_equipment_components() {
            $("#lubricant_code_equipment_components_grid").children(".k-grid-content").height($(window).height() - 590);
            $("#lubricant_code_products_grid").children(".k-grid-content").height($(window).height() - 590);

        }

        $(window).resize(function () {
            resize_window_of_lubricant_code_equipment_components();
        });


        $("#lubricant_code_equipment_components_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: {
                        url: '/' + gon.locale + "/equipment_components.json",
                        data: {
                            filter: {
                                logic: "and",
                                // filters: [
                                //     {field: "equipment_id", operator: "eq", value: gon.equipment_id}
                                // ],
                                custom_filters: [
                                    {field: "lubricant_code_id", operator: "eq", value: gon.lubricant_code_id}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            name: {type: "string"},
                            equipment_model: {type: "string"},
                            component_type_name_en: {type: "string"},
                            operating_regimes_name_en: {type: "string"},
                            drive_type_name: {type: "string"},
                            component_code: {type: "string"},
                            capacities: {type: "string"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_lubricant_code_equipment_components();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/equipment_components/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'equipment_model'),  //"Название отчета",
                    field: "equipment_model",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'name'),  //"Название отчета",
                    field: "name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'component_type_name'),  //"Название отчета",
                    field: "component_type_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getEquipmentComponentsColumnNameByCulture(gon.locale, 'capacities'),  //"Название отчета",
                    field: "capacities",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },


            ]
        });


    }

});