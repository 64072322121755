$(document).ready(function () {


    // --------------  Categories Index page ---------------
    var categories_index_page = document.querySelector('#categories_grid');

    if (categories_index_page != undefined) {

        var category_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name_en: 'Категория en',
                name_ru: 'Категория ru',
                category_group: 'Группа',
            },
            en: {
                created_at: 'Created at',
                name_en: 'Category en',
                name_ru: 'Category ru',
                category_group: 'Group',
            }
        };

        function getCategoryColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return category_dictionary[culture][word]
        }

        function resize_window_of_categories() {
            $("#categories_grid").children(".k-grid-content").height($(window).height() - 260);
        }

        $(window).resize(function () {
            resize_window_of_categories();
        });


        $("#categories_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/categories.json"
                },
                group: [{
                    title: 'Category group',
                    field: "category_group",
                    dir: "asc",
                }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                            category_group: {type: "string"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_categories();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
//            navigatable: true,
//            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/categories/#=id#/edit">Edit</i></a></div>',
                    width: 120
                },
                {
                    title: getCategoryColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 180
                },
                {
                    title: getCategoryColumnNameByCulture(gon.locale, 'name_en'),
                    field: "name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getCategoryColumnNameByCulture(gon.locale, 'name_ru'),
                    field: "name_ru",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getCategoryColumnNameByCulture(gon.locale, 'category_group'),
                    field: "category_group",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },

            ]
        });

    }

});