$(document).ready(function () {

    // --------------  Statistics Edit page ---------------
    var statistics_index_page = document.querySelector('#statistics_edit_page');

    if (statistics_index_page != undefined) {


        $("#statistic_user_id").width('100%').kendoDropDownList({
            filter: "startswith",
            dataTextField: "email",
            dataValueField: "id",
            height: 500,
            template: '<b>(#: data.organization_name #)</b><p>#: data.email #</p>',

            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        url: "/" + gon.locale + '/users.json',
                        dataType: "json",
                    }
                },
                // group: [{
                //     title: 'Organization',
                //     field: "organization_name",
                //     dir: "asc",
                // }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            email: {type: "string"},
                            organization_name: {type: "string"},
                        }
                    }
                },
                // group: { field: "organization_name" }
            }
        });

    }

});