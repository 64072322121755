
$(document).ready(function () {

    // --------------  Lubricant show components Index page ---------------
    var lubricant_code_equipment_products_index_page = document.querySelector('#lubricant_code_products_grid');

    if (lubricant_code_equipment_products_index_page != undefined) {

        var products_dictionary = {
            ru: {
                created_at: 'Дата создания',
                product_manufacturer_name: 'Производитель',
                vendor_code: 'Код вендора',
                vendor_code2: 'Код вендора 2',
                name: 'Название',
                priority: 'Приоритет',
                product_image_attached: "Картинка продукта",
                organization_name: "Организация",
                is_active: "Активен",
                is_active_by_product_manufacturer: "Производитель активен",
                is_description_ru: "is ru",
                is_description_en: "is en",
                is_description_ja: "is ja",
                packagings_count: "Кол-во фасовок"
            },
            en: {
                created_at: 'Created at',
                product_manufacturer_name: 'Manufacturer',
                vendor_code: 'Vendor code',
                vendor_code2: 'Vendor code 2',
                name: 'Name',
                priority: 'Priority',
                product_image_attached: "Image of product",
                organization_name: "Organization",
                is_active: "Is active",
                is_active_by_product_manufacturer: "Manufacturer is active",
                is_description_ru: "is ru",
                is_description_en: "is en",
                is_description_ja: "is ja",
                packagings_count: "packagings count"
            }
        };

        function getProductsColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return products_dictionary[culture][word]
        }

        function resize_window_of_lubricant_code_equipment_components() {
            $("#lubricant_code_equipment_components_grid").children(".k-grid-content").height($(window).height() - 590);
            $("#lubricant_code_products_grid").children(".k-grid-content").height($(window).height() - 590);

        }

        $(window).resize(function () {
            resize_window_of_lubricant_code_equipment_components();
        });


        $("#lubricant_code_products_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: {
                        url: '/' + gon.locale + "/products.json",
                        data: {
                            filter: {
                                logic: "and",
                                // filters: [
                                //     {field: "equipment_id", operator: "eq", value: gon.equipment_id}
                                // ],
                                custom_filters: [
                                    {field: "lubricant_code_id", operator: "eq", value: gon.lubricant_code_id}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            product_manufacturer_name_en: {type: "string"},
                            vendor_code: {type: "string"},
                            vendor_code2: {type: "string"},
                            name: {type: "string"},
                            priority: {type: "integer"},
                            created_at: {type: "date"},
                            updated_at: {type: "date"},
                            product_image_attached: {type: "boolean"},
                            organization_name: {type: "string"},
                            is_active: {type: "boolean"},
                            is_active_by_product_manufacturer: {type: "boolean"},
                            is_description_ru: {type: "boolean"},
                            is_description_en: {type: "boolean"},
                            is_description_ja: {type: "boolean"},
                            packagings_count: {type: "integer"}
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_lubricant_code_equipment_components();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/products/#=id#">Show</i></a></div>',
                    width: 50
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/products/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 150
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'organization_name'),  //"Название организации",
                    field: "organization_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'product_manufacturer_name'),  //"Название отчета",
                    field: "product_manufacturer_name_en",
                    template: '<div class="flex items-center justify-center"># if (data.is_active_by_product_manufacturer) {# <span class="text-green-500">#: data.product_manufacturer_name_en #</span>#} else { # <span class="text-red-500">#: data.product_manufacturer_name_en #</span> # } #</div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'name'),  //
                    field: "name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'vendor_code'),  //
                    field: "vendor_code",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                // {
                //     title: getProductsColumnNameByCulture(gon.locale, 'vendor_code2'),  //
                //     field: "vendor_code2",
                //     filterable: {
                //         cell: {
                //             showOperators: false
                //         }
                //     },
                //     width: 100
                // },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'priority'),  //
                    field: "priority",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 50
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'packagings_count'),  //
                    field: "packagings_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    // filterable: false,
                    sortable: false,
                    width: 50
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'is_description_ru'),  //
                    field: "is_description_ru",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_description_ru) {# ru #}#</span></div>',
                    filterable: false,
                    sortable: false,
                    width: 47
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'is_description_en'),  //
                    field: "is_description_en",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_description_en) {# en #}#</span></div>',
                    filterable: false,
                    sortable: false,
                    width: 47
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'is_description_ja'),  //
                    field: "is_description_ja",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_description_ja) {# ja #}#</span></div>',
                    filterable: false,
                    sortable: false,
                    width: 47
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'product_image_attached'),  //
                    field: "product_image_attached",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.product_image_attached) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 100
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'is_active'),  //
                    field: "is_active",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_active) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 100
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'is_active_by_product_manufacturer'),  //
                    field: "is_active_by_product_manufacturer",
                    template: '<div class="flex items-center justify-center"># if (data.is_active_by_product_manufacturer) {# <span class="text-green-500"><i class="fa-solid fa-circle-check"></i></span>#} else { # <span class="text-red-500"><i class="fa-solid fa-xmark"></i></span> # } #</div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 100
                },


            ]
        });

    }

});