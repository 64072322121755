$(document).ready(function () {

    // --------------  Product Edit page ---------------
    var product_compares_show_page = document.querySelector('#product_compares_show_disable');

    if (product_compares_show_page != undefined) {

        Highcharts.chart('component_types_group', {
            chart: {
                type: 'column',
                zoomType: 'x',
            },
            title: {
                text: 'Component types'
            },
            subtitle: {
                text: `Number of component types match this product`
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                title: {
                    text: null
                },
                min: 0,
                max: gon.component_types_groups_max_axis,
                scrollbar: {
                    enabled: true
                },
                tickLength: 0
            },
            yAxis: {
                title: {
                    text: 'Number of components match'
                }

            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    }
                }
            },

            // tooltip: {
            //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            // },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: gon.product_name_original,
                    // colorByPoint: true,
                    color: 'rgb(255,128,30)',
                    data: gon.component_types_groups_original
                },
                {
                    name: gon.product_name,
                    // colorByPoint: true,
                    color: 'rgb(108,108,108)',
                    data: gon.component_types_groups
                }
            ],

        });



        Highcharts.chart('equipment_category_group', {
            chart: {
                type: 'column',
                zoomType: 'x',
            },
            title: {
                text: 'Equipment category'
            },
            subtitle: {
                text: `Number of equipment category match this product`
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                title: {
                    text: null
                },
                min: 0,
                max: gon.equipment_category_groups_max_axis,
                scrollbar: {
                    enabled: true
                },
                tickLength: 0
            },
            yAxis: {
                title: {
                    text: 'Number of equipment category match'
                }

            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    }
                }
            },

            // tooltip: {
            //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            // },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: gon.product_name_original,
                    // colorByPoint: true,
                    color: 'rgb(255,128,30)',
                    data: gon.equipment_category_groups_original
                },
                {
                    name: gon.product_name,
                    // colorByPoint: true,
                    color: 'rgb(108,108,108)',
                    data: gon.equipment_category_groups
                }
            ],

        });

        // Equipment manufacturers match
        Highcharts.chart('equipment_manufacturers_group', {
            chart: {
                type: 'column',
                // type: 'bar',
                zoomType: 'x',
                // scrollablePlotArea: true,
            },
            title: {
                text: 'Equipment manufacturers'
            },
            subtitle: {
                text: `Number of equipment manufacturers match this product`
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                title: {
                    text: null
                },
                min: 0,
                max: gon.equipment_manufacturers_groups_max_axis,
                scrollbar: {
                    enabled: true
                },
                tickLength: 0
            },
            yAxis: {
                title: {
                    text: 'Number of equipment manufacturers match'
                },
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    }
                }
            },

            // tooltip: {
            //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            // },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: gon.product_name_original,
                    // colorByPoint: true,
                    color: 'rgb(255,128,30)',
                    data: gon.equipment_manufacturers_groups_original
                },
                {
                    name: gon.product_name,
                    // colorByPoint: true,
                    color: 'rgb(108,108,108)',
                    data: gon.equipment_manufacturers_groups
                }
            ],

        });


    }

});