$(document).ready(function () {


    // --------------  User API activities graph page ---------------
    var user_api_activities_index_page_graph = document.querySelector('#graph_user_activity_last_30_days_2');

    if (user_api_activities_index_page_graph != undefined) {
        // Get date from Date()
        let lastDay = new Date();
        let firstDay = new Date(new Date().setDate(lastDay.getDate() - 30));

        if ((gon.date_from == undefined) || (gon.date_to == undefined)) {
            gon.date_from = firstDay;
            gon.date_to = lastDay;
        }

        let graph_user_activity_last_30_days_2_options = {
            chart: {
                // type: 'spline',
                // type: 'column',
                height: 380,
                zoomType: 'x',
                zooming: {
                    mouseWheel: false,
                }
            },
            // chart: {
            //     zoomType: 'x'
            // },
            legend: {
                align: "right",
                layout: "vertical",
                verticalAlign: "bottom",
                // floating: "true",
                // x: 40,
                y: 20,
                navigation: {
                    enabled: "true",
                }
            },
            title: {
                text: 'User API requests'
            },
            subtitle: {
                text:  new Date(Date.parse(gon.date_from)).toLocaleDateString() + ' - ' + new Date(Date.parse(gon.date_to)).toLocaleDateString()
            },
            xAxis: {
                type: 'datetime',
                // dateTimeLabelFormats: { // don't display the dummy year
                //     month: '%e. %b',
                //     year: '%b'
                // },
                title: {
                    text: 'Date'
                },
                // min: gon.last_month_date
            },
            yAxis: {
                title: {
                    text: 'Requests'
                },
                // min: 0
                // min: function() {
                //     var future = new Date();
                //     return future.setDate(future.getDate() - 30);
                // },
                // min: gon.last_month_date

            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x:%e. %b}: {point.y} requests'
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true
                    }
                }
            },
            // colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
            credits: {
                enabled: false
            },
            // series: gon.user_activity_series,
            // series: [{}],
            exporting: {
                sourceWidth: 1280,
                sourceHeight: 1024,
                // scale: 2 (default)
                // chartOptions: {
                //     subtitle: null
                // }
            },
            // series: [
            //     {
            //         name: "ivan.kozlov@gmail.com",
            //         data: [
            //             [Date.UTC(2022, 5, 21), 5],
            //             [Date.UTC(2022, 5, 22), 3]
            //         ]
            //     },
            //     {
            //         name: "adv@aaa.ru",
            //         data: [
            //             [Date.UTC(2022, 5, 12), 1]
            //         ]
            //     }
            // ],


            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        plotOptions: {
                            series: {
                                marker: {
                                    radius: 2.5
                                }
                            }
                        }
                    }
                }]
            }
        };


        var organization_params = 'date_from=' + gon.date_from + '&date_to=' + gon.date_to;


        Highcharts.ajax({
            url: '/statistics_process/organization_activities.json?' + organization_params,
            success: function (data) {
                data = jQuery.parseJSON(data);
                let graph_user_activity_last_30_days_2 = Highcharts.chart('graph_user_activity_last_30_days_2', graph_user_activity_last_30_days_2_options);
                window.graph_user_activity_last_30_days_2 = graph_user_activity_last_30_days_2;

                let total_visits = {}
                let chart_index = 1;

                // Iterate over all organizations
                $.each(data['organization_activity'], function (organization_name, visits_data) {

                    // Iterate by all visits of each organization
                    $.each(visits_data, function (timestamp, visits_count) {
                        // console.log(timestamp.toString() + ' -> ' + visits_count[0].toString() + ' | ' + visits_count[1].toString());

                        // Store and sum total activity by all organizations to hash first
                        if (total_visits[visits_count[0]]) {
                            total_visits[visits_count[0]] = parseInt(total_visits[visits_count[0]]) + parseInt(visits_count[1]);
                        } else {
                            total_visits[visits_count[0]] = parseInt(visits_count[1]);
                        }
                    })

                    // Draw activity on each organization
                    graph_user_activity_last_30_days_2.addSeries({
                        index: chart_index,
                        name: organization_name,
                        data: visits_data,
                        type: 'column'
                    }, false)

                    chart_index += 1;

                    // console.log(visits_data);
                });

                // console.log(total_visits);

                // Convert hash with total activity to array for highcharts
                let total_visits_arr = []
                let keys = Object.keys(total_visits);

                // Sort by hash key and push ordered by key (timestamp) to total visits array
                keys.sort().forEach((key) => {
                    total_visits_arr.push([parseInt(key), total_visits[key]]);
                })

                // Add Total to graphs
                graph_user_activity_last_30_days_2.addSeries({
                    index: 0,
                    name: 'Total',
                    data: total_visits_arr,

                    type: 'area',
                    threshold: null,
                    fillOpacity: 0.1,
                    color: 'rgba(44, 233, 11, 0.2)',
                    lineColor: 'rgba(44, 233, 11, 0.2)'
                }, false)

                // Redraw chart to visualise all added graphs
                graph_user_activity_last_30_days_2.redraw();
            }
        });

        let visible = true;
        // Bind to 'button_show_hide_graph_user_activity_last_30_days_2' button
        let button_show_hide_graph_user_activity = document.querySelector('#button_show_hide_graph_user_activity_last_30_days_2');

        if (button_show_hide_graph_user_activity != undefined) {
            button_show_hide_graph_user_activity_last_30_days_2.addEventListener('click', () => {
                if (visible) {
                    $(graph_user_activity_last_30_days_2.series).each(function () {
                        //this.hide();
                        this.setVisible(false, false);
                    });
                    graph_user_activity_last_30_days_2.redraw();

                } else {
                    $(graph_user_activity_last_30_days_2.series).each(function () {
                        //this.hide();
                        this.setVisible(true, false);
                    });
                    graph_user_activity_last_30_days_2.redraw();
                }
                visible = !visible;
            })
        }

    }

});