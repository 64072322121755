$(document).ready(function () {

    // --------------  Product Library Index page ---------------
    var product_library_index_page = document.querySelector('#product_library_grid');

    if (product_library_index_page != undefined) {

        var products_dictionary = {
            ru: {
                created_at: 'Создан',
                product_manufacturer_name: 'Производитель',
                vendor_code: 'Код вендора',
                vendor_code2: 'Код вендора 2',
                name: 'Название',
                priority: 'Приоритет',
                product_image_attached: "Картинка продукта",
                organization_name: "Организация",
                is_active: "Активен",
                is_active_by_product_manufacturer: "Производитель активен",
                is_description_ru: "is ru",
                is_description_en: "is en",
                is_description_ja: "is ja",
                packagings_count: "Фасовок",
                lubricant_codes: "Коды",
                oil_viscosities: "Вязкости",
            },
            en: {
                created_at: 'Created',
                product_manufacturer_name: 'Manufacturer',
                vendor_code: 'Vendor code',
                vendor_code2: 'Vendor code 2',
                name: 'Name',
                priority: 'Priority',
                product_image_attached: "Image of product",
                organization_name: "Organization",
                is_active: "Is active",
                is_active_by_product_manufacturer: "Manufacturer is active",
                is_description_ru: "is ru",
                is_description_en: "is en",
                is_description_ja: "is ja",
                packagings_count: "Packagings",
                lubricant_codes: "Codes",
                oil_viscosities: "Viscosities",
            }
        };

        function getProductsColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return products_dictionary[culture][word]
        }

        function resize_window_of_products() {
            $("#product_library_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window_of_products();
        });

        // function products_table_row_coloring() {
        //     var grid = $("#product_library_grid").data("kendoGrid");
        //     var data = grid.dataSource.data();
        //
        //     $.each(data, function (i, row) {
        //
        //         if (row.is_active == true) {
        //             $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ddecd5");
        //         } else {
        //             $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f6b8c3");
        //         }
        //
        //         if (row.is_active_by_product_manufacturer == false) {
        //             $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ffacbb");
        //         }
        //
        //     });
        // }


        function createMultiSelectForIndustrialCodes(element) {
            element.removeAttr("data-bind");

            element.kendoMultiSelect({
                dataSource: gon.lubricant_codes,
                filter: "contains",
                autoClose: false,
                change: function (e) {
                    // var filter = {logic: "or", filters: []};
                    let filter = dataSourceProducts.filter(); //.filters;
                    var values = this.value();
                    // console.log(this.value());
                    // console.log(filter);

                    // Copy only not lubricant code filters
                    let not_column_name_filters = [];
                    if (filter.filters != undefined) {
                        $.each(filter.filters, function (i, v) {
                            // console.log("aaaa");
                            // console.log(v.field);
                            if (v.field != 'lubricant_codes') {
                                not_column_name_filters.push(v);
                            }
                        });
                        filter.filters = not_column_name_filters;
                    }

                    $.each(values, function (i, v) {
                        filter.filters.push({field: "lubricant_codes", operator: "eq", value: v});
                    });
                    dataSourceProducts.filter(filter);


                    // console.log(dataSourceProducts);
                }
            });
        }

        function createMultiSelectForOilViscosities(element) {
            element.removeAttr("data-bind");

            element.kendoMultiSelect({
                dataSource: gon.oil_viscosities,
                filter: "contains",
                autoClose: false,
                change: function (e) {
                    // var filter = {logic: "or", filters: []};
                    let filter = dataSourceProducts.filter();
                    var values = this.value();
                    // console.log(this.value());

                    // Copy only not viscosity filters
                    let not_column_name_filters = [];
                    $.each(filter.filters, function (i, v) {
                        // console.log("aaaa");
                        // console.log(v.field);
                        if (v.field != 'oil_viscosities') {
                            not_column_name_filters.push(v);
                        }
                    });
                    filter.filters = not_column_name_filters;

                    $.each(values, function (i, v) {
                        filter.filters.push({field: "oil_viscosities", operator: "eq", value: v});
                    });
                    dataSourceProducts.filter(filter);
                }
            });
        }


        var dataSourceProducts = new kendo.data.DataSource(
            {
                // type: "json",
                sort: {field: "product_manufacturer_name_en", dir: "asc"},
                transport: {
                    read: '/' + gon.locale + "/analytics/product_libraries.json"
                },
                group: [{
                    title: 'Product manufacturer',
                    field: "product_manufacturer_name_en",
                    dir: "asc",
                }],
                filter: {logic: "and", filters: []},
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            product_manufacturer_name_en: {type: "string"},
                            vendor_code: {type: "string"},
                            vendor_code2: {type: "string"},
                            name: {type: "string"},
                            // priority: {type: "integer"},
                            created_at: {type: "date"},
                            updated_at: {type: "date"},
                            product_image_attached: {type: "boolean"},
                            // organization_name: {type: "string"},
                            // is_active: {type: "boolean"},
                            // is_active_by_product_manufacturer: {type: "boolean"},
                            // is_description_ru: {type: "boolean"},
                            // is_description_en: {type: "boolean"},
                            // is_description_ja: {type: "boolean"},
                            // packagings_count: {type: "integer"},
                            lubricant_codes: {type: "string"},
                            oil_viscosities: {type: "string"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            }
        );

        $("#product_library_grid").kendoGrid({
            dataSource: dataSourceProducts,
            dataBound: function (e) {
                resize_window_of_products();
                // products_table_row_coloring();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/analytics/product_libraries/#=id#">Show</i></a></div>',
                    width: 55
                },
                // {
                //     template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/products/#=id#/edit">Edit</i></a></div>',
                //     width: 50
                // },
                // {
                //     title: getProductsColumnNameByCulture(gon.locale, 'organization_name'),  //"Название организации",
                //     field: "organization_name",
                //     filterable: {
                //         cell: {
                //             showOperators: false
                //         }
                //     },
                //     width: 120
                // },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'product_manufacturer_name'),  //"Название отчета",
                    field: "product_manufacturer_name_en",
                    // template: '<div class="flex items-center justify-center"># if (data.is_active_by_product_manufacturer) {# <span class="text-green-500">#: data.product_manufacturer_name_en #</span>#} else { # <span class="text-red-500">#: data.product_manufacturer_name_en #</span> # } #</div>',
                    template: '<div class="flex items-center justify-center font-bold">#: data.product_manufacturer_name_en #</div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 250
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'name'),  //
                    field: "name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 300
                },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'vendor_code'),  //
                    field: "vendor_code",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 200
                },
                // {
                //     title: getProductsColumnNameByCulture(gon.locale, 'lubricant_codes'),  //
                //     field: "lubricant_codes",
                //     filterable: {
                //         cell: {
                //             showOperators: false
                //         }
                //     },
                //     width: 100
                // },
                {
                    title: getProductsColumnNameByCulture(gon.locale, 'lubricant_codes'),
                    field: "lubricant_codes",
                    template: '<div class="text-xs">#: data.lubricant_codes #</div>',
                    filterable: {
                        cell: {
                            template: function (args) {
                                createMultiSelectForIndustrialCodes(args.element);
                            },
                            showOperators: false
                        }
                    },
                    sortable: false,
                    // width: 180
                },

                {
                    title: getProductsColumnNameByCulture(gon.locale, 'oil_viscosities'),  //
                    field: "oil_viscosities",
                    filterable: {
                        cell: {
                            template: function (args) {
                                createMultiSelectForOilViscosities(args.element);
                            },
                            showOperators: false
                        }
                    },
                    width: 120
                },

                // {
                //     title: getProductsColumnNameByCulture(gon.locale, 'packagings_count'),  //
                //     field: "packagings_count",
                //     filterable: {
                //         cell: {
                //             showOperators: false
                //         }
                //     },
                //     // filterable: false,
                //     sortable: false,
                //     width: 50
                // },

            ]
        });

    }

});