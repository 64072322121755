$(document).ready(function () {

    // --------------  Users Index page ---------------
    var users_index_page = document.querySelector('#users_grid');

    if (users_index_page != undefined) {

        var users_dictionary = {
            ru: {
                created_at: 'Дата создания',
                email: 'Email',
                sign_in_count: 'Входов',
                api_call_total_count: 'API запросов',
                last_sign_in_at: 'Последний вход',
                last_sign_in_ip: 'Последний IP',
                is_admin: 'Администратор',
                contract_number: 'Номер договора',
                is_active: 'Активен',
                is_active_until: 'Активен до',
                is_api_available: 'API Equipment',
                is_api_crossing: 'API Crossing',
                is_api_multibrand: 'API Multibrand',
                organization_name: 'Организация',
                is_template: 'Шаблон',
            },
            en: {
                created_at: 'Created at',
                email: 'Email',
                sign_in_count: 'Sign ins',
                api_call_total_count: 'API calls',
                last_sign_in_at: 'Last sign in',
                last_sign_in_ip: 'Last ip',
                is_admin: 'Is admin',
                contract_number: 'Contract number',
                is_active: 'Is active',
                is_active_until: 'Active until',
                is_api_available: 'API Equipment',
                is_api_crossing: 'API Crossing',
                is_api_multibrand: 'API Multibrand',
                organization_name: 'Organization',
                is_template: 'Template',
            }
        };

        function getUsersColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return users_dictionary[culture][word]
        }

        function resize_window_of_users() {
            $("#users_grid").children(".k-grid-content").height($(window).height() - 240);
        }

        $(window).resize(function () {
            resize_window_of_users();
        });


        function users_table_row_coloring() {
            var grid = $("#users_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_template == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#e1e7ea");
                } else if (row.is_admin == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f6e4b8");
                } else if (row.is_active == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#cfe8c3");
                } else if (row.is_api_available == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#dac3e8");
                }

            });
        }


        $("#users_grid").kendoGrid({
            dataSource: {
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/users.json"
                },
                group: [{
                    title: 'Organization',
                    field: "organization_name",
                    dir: "asc",
                }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            email: {type: "string"},
                            sign_in_count: {type: "string"},
                            last_sign_in_at: {type: "date"},
                            last_sign_in_ip: {type: "string"},
                            is_admin: {type: "boolean"},
                            is_active: {type: "boolean"},
                            is_template: {type: "boolean"},
                            created_at: {type: "date"},
                            contract_number: {type: "string"},
                            organization_name: {type: "string"},
                            is_api_available: {type: "boolean"},
                            is_api_crossing: {type: "boolean"},
                            is_api_multibrand: {type: "boolean"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_users();

                users_table_row_coloring();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/users/#=id#/">Show</i></a></div>',
                    width: 50
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/users/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    format: "{0:dd.MM.yyyy}",
                    type: "date",
                    filterable: false,
                    width: 80
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'last_sign_in_at'),
                    field: "last_sign_in_at",
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 140
                },
                // {
                //     title: getUsersColumnNameByCulture(gon.locale, 'is_active_until'),  //"Дата создания",
                //     field: "is_active_until",
                //     format: "{0:dd.MM.yyyy | HH:mm:ss}",
                //     type: "date",
                //     filterable: false,
                //     width: 160
                // },
                // {
                //     title: getUsersColumnNameByCulture(gon.locale, 'contract_number'),
                //     template: '# if (data.contract_number) {#<div class="text-xs rounded-md bg-blue-100 py-0.5 px-1 mx-2">#: data.contract_number #</div>#}#',
                //     field: "contract_number",
                //     filterable: {
                //         cell: {
                //             showOperators: false
                //         }
                //     },
                //     width: 120
                // },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'email'),  //"email",
                    field: "email",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'organization_name'),  //"user_role_name",
                    field: "organization_name",
                    filterable: false,
                    width: 100
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'sign_in_count'),
                    field: "sign_in_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 65
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'api_call_total_count'),
                    field: "api_call_total_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 65
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'last_sign_in_ip'),
                    field: "last_sign_in_ip",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 110
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_template'),  //"is_admin",
                    field: "is_template",
                    template: '<div class="flex items-center justify-center"><span style="color:blue"># if (data.is_template) {#<i class="fa-regular fa-sparkles"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_api_available'),  //"user_role_name",
                    field: "is_api_available",
                    template: '<div class="flex items-center justify-center"><span style="color:\\#EC8A33"># if (data.is_api_available) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_api_crossing'),  //"user_role_name",
                    field: "is_api_crossing",
                    template: '<div class="flex items-center justify-center"><span style="color:\\#E9602C"># if (data.is_api_crossing) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_api_multibrand'),  //"user_role_name",
                    field: "is_api_multibrand",
                    template: '<div class="flex items-center justify-center"><span style="color:\\#EA346F"># if (data.is_api_multibrand) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_active'),  //"is_admin",
                    field: "is_active",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_active) {#<i class="fa-solid fa-wave-pulse"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_admin'),  //"is_admin",
                    field: "is_admin",
                    template: '<div class="flex items-center justify-center"><span style="color:\\#f77d36"># if (data.is_admin) {#<i class="fa-light fa-citrus-slice"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
            ]
        });

    }

});