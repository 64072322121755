$(document).ready(function () {


    // --------------  Manufacturer Show page ---------------
    var manufacturer_show_page = document.querySelector('#manufacturer_show_equipment_grid');

    if (manufacturer_show_page != undefined) {

        var manufacturer_equipment_dictionary = {
            ru: {
                created_at: 'Дата создания',
                model: 'Модель',
                category_name: 'Категория',
                manufacturer_name: 'Производитель',
                fuel_type_name: 'Тип топлива',
                series_name: 'Серия',
                product_group_name: 'Подуктовая группа',
                year_from: 'Начало производства',
                year_to: 'Окончание производства',
                components_count: 'Количество компонентов',
            },
            en: {
                created_at: 'Created at',
                model: 'Model',
                category_name: 'Category',
                category_name_en: 'Category',
                fuel_type_name: 'Fuel type',
                series_name: 'Series',
                product_group_name: 'Product group',
                year_from: 'Start of manufacturing',
                year_to: 'End of manufacturing',
                components_count: 'Components count',
            }
        };

        function getManufacturerEquipmentColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return manufacturer_equipment_dictionary[culture][word]
        }

        function resize_window_of_operating_manufacturer_equipment() {
            $("#manufacturer_show_equipment_grid").children(".k-grid-content").height($(window).height() - 220);
        }

        $(window).resize(function () {
            resize_window_of_operating_manufacturer_equipment()
        });

        // console.log('manufacturer_show_equipment_grid index');

        function manufacturerRowColoringOnDatabound() {
            var grid = $("#manufacturer_show_equipment_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == 1) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#c5ebc7"); //green
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#eca6b0"); //red deactivated
                }
            });
        }

        $("#manufacturer_show_equipment_grid").kendoGrid({
            dataSource: {
                // sort: {field: "priority", dir: "desc"},
                transport: {
                    read: {
                        url: '/' + gon.locale + "/equipment.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "manufacturer_id", operator: "eq", value: gon.manufacturer_id}
                                ]
                            }
                        }
                    }
                },
                group: [{
                    title: 'Category',
                    field: "category_name_en",
                    dir: "asc",
                }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            model: {type: "string"},
                            category_name_en: {type: "string"},
                            manufacturer_name_en: {type: "string"},
                            fuel_type_name_en: {type: "string"},
                            series_name_en: {type: "string"},
                            product_group_name_en: {type: "string"},
                            year_from: {type: "string"},
                            year_to: {type: "string"},
                            capacities: {type: "string"},
                            components_count: {type: "string"},
                            is_active: {type: "boolean"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_operating_manufacturer_equipment();
                manufacturerRowColoringOnDatabound();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/equipment/#=id#/">Show</i></a></div>',
                    width: 60
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/equipment/#=id#/edit">Edit</i></a></div>',
                    width: 60
                },
                // {
                //     title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'manufacturer_name'),  //"Название производителя",
                //     field: "manufacturer_name_en",
                //     filterable: {
                //         cell: {
                //             showOperators: false
                //         }
                //     },
                //     width: 100
                // },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'model'),  //"Название модели",
                    field: "model",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },

                },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'category_name'),  //"Название категории",
                    field: "category_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 60,
                    hidden: true
                },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'fuel_type_name'),  //"Название типа топлива",
                    field: "fuel_type_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 60
                },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'series_name'),  //"Название серии",
                    field: "series_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'product_group_name'),  //"Название группы",
                    field: "product_group_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 150
                },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'year_from'),  //"Год начала выпуска",
                    field: "year_from",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'year_to'),  //"Год окончания выпуска",
                    field: "year_to",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },
                {
                    title: getManufacturerEquipmentColumnNameByCulture(gon.locale, 'components_count'),  //"Год окончания выпуска",
                    field: "components_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },

            ]
        });

    }

});