$(document).ready(function () {

    // --------------  Users Index page ---------------
    var users_index_page = document.querySelector('#user_api_access_controls_grid');

    if (users_index_page != undefined) {

        var users_dictionary = {
            ru: {
                created_at: 'Дата создания',
                updated_at: "Изменен",
                user_api_activity_type_name: "Тип активности",
                email: 'Email',
                ip: "IP",
                requests: "Запросы",
                is_active: "Активен",
                is_static: "Статичен",
                organization_name: "Организация",
                is_api_activity_control: "API контроль"
            },
            en: {
                created_at: 'Created at',
                updated_at: "Changed at",
                user_api_activity_type_name: "Activity type",
                email: 'Email',
                ip: "IP",
                requests: "Requests",
                is_active: "Is active",
                is_static: "Is static",
                organization_name: "Organization",
                is_api_activity_control: "API control"
            }
        };

        function getUsersColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return users_dictionary[culture][word]
        }

        function resize_window_of_users() {
            $("#user_api_access_controls_grid").children(".k-grid-content").height($(window).height() - 240);
        }

        $(window).resize(function () {
            resize_window_of_users();
        });


        function users_table_row_coloring() {
            var grid = $("#user_api_access_controls_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {
                if (row.is_active == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "rgba(234,0,0,0.24)");
                }

                // if (row.is_static == true) {
                //     $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#c3d0e8");
                // }
            });
        }


        $("#user_api_access_controls_grid").kendoGrid({
            dataSource: {
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/user_api_access_controls.json"
                },
                group: [{
                    title: 'Organization',
                    field: "organization_name",
                    dir: "asc",
                }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            organization_name: {type: "string"},
                            user_api_activity_type_id: {type: "integer"},
                            user_api_activity_type_name: {type: "string"},
                            email: {type: "string"},
                            ip: {type: "string"},
                            requests: {type: "integer"},
                            is_active: {type: "boolean"},
                            is_static: {type: "boolean"},
                            created_at: {type: "date"},
                            updated_at: {type: "date"},
                            is_api_activity_control: {type: "boolean"},
                            comments: {type: "string"}
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_users();

                users_table_row_coloring();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/user_api_access_controls/#=id#/">Show</i></a></div>',
                    width: 50
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/user_api_access_controls/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_api_activity_control'),
                    field: "is_api_activity_control",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_api_activity_control) {#<i class="fa-solid fa-wave-pulse"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    format: "{0:dd.MM.yyyy}",
                    type: "date",
                    filterable: false,
                    width: 80
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'updated_at'),  //"Дата создания",
                    field: "updated_at",
                    format: "{0:dd.MM.yyyy}",
                    type: "date",
                    filterable: false,
                    width: 80
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'organization_name'),  //"user_role_name",
                    field: "organization_name",
                    filterable: false,
                    width: 150
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'user_api_activity_type_name'),  //"user_role_name",
                    field: "user_api_activity_type_name",
                    filterable: false,
                    width: 150
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'email'),  //"email",
                    field: "email",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 200
                },

                {
                    title: getUsersColumnNameByCulture(gon.locale, 'ip'),
                    field: "ip",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 150
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'requests'),
                    field: "requests",
                    filterable: false,
                    width: 100
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_active'),  //"is_admin",
                    field: "is_active",
                    template: '<div class="flex items-center justify-center"><span style="color:red"># if (data.is_active) {#<i class="fa-solid fa-hand"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'is_static'),  //"is_admin",
                    field: "is_static",
                    template: '<div class="flex items-center justify-center"><span style="color:blue"># if (data.is_static) {#<i class="fa-regular fa-thumbtack"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 90
                },
                {
                    title: getUsersColumnNameByCulture(gon.locale, 'comments'),
                    field: "comments",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
            ]
        });

    }

});