
$(document).ready(function () {


    // --------------  Manufacturerss Index page ---------------
    var manufacturers_index_page = document.querySelector('#manufacturers_grid');

    if (manufacturers_index_page != undefined) {

        var category_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name_en: 'Производитель EN',
                name_ru: 'Производитель RU',
                is_active: 'Активен',
                equipment_count: '# Обор-е',
                active_equipment_count: '# Обор-е активно',
                disabled_equipment_count: '# Обор-е отключено',
                is_logo_image: "Логотип",
            },
            en: {
                created_at: 'Created at',
                name_en: 'Manufacturer EN',
                name_ru: 'Manufacturer RU',
                is_active: 'Active',
                equipment_count: '# Equipmen',
                active_equipment_count: '# Equipmen active',
                disabled_equipment_count: '# Equipmen disabled',
                is_logo_image: "Logo"
            }
        };

        function getManufacturerColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return category_dictionary[culture][word]
        }

        function resize_window_of_manufacturers() {
            $("#manufacturers_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window_of_manufacturers();
        });

        function manufacturers_table_row_coloring() {
            var grid = $("#manufacturers_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ddecd5");
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f6b8c3");
                }

                if (row.is_active_by_product_manufacturer == false) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ffacbb");
                }

            });
        }

        $("#manufacturers_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/manufacturers.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                            is_active: {type: "boolean"},
                            equipment_count: {type: "string"},
                            active_equipment_count: {type: "string"},
                            disabled_equipment_count: {type: "string"},
                            is_logo_image: {type: "boolean"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_manufacturers();
                manufacturers_table_row_coloring();
            },
            sortable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
//            navigatable: true,
//            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/manufacturers/#=id#">Show</i></a></div>',
                    width: 60
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/manufacturers/#=id#/edit">Edit</i></a></div>',
                    width: 60
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 180
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'name_en'),
                    field: "name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'name_ru'),
                    field: "name_ru",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'equipment_count'),
                    template: '<div class="flex items-center justify-center font-bold"><span style="color:black">#= data.equipment_count #</span></div>',
                    field: "equipment_count",
                    filterable: false,
                    width: 130
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'active_equipment_count'),
                    template: '<div class="flex items-center justify-center"><span style="color:green">#= data.active_equipment_count #</span></div>',
                    field: "active_equipment_count",
                    filterable: false,
                    width: 130
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'disabled_equipment_count'),
                    template: '<div class="flex items-center justify-center"><span style="color:red">#= data.disabled_equipment_count #</span></div>',
                    field: "disabled_equipment_count",
                    filterable: false,
                    width: 130
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'is_active'),
                    field: "is_active",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_active) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 110
                },
                {
                    title: getManufacturerColumnNameByCulture(gon.locale, 'is_logo_image'),
                    field: "is_logo_image",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_logo_image) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 110
                },




            ]
        });

    }

});