$(document).ready(function () {

    // --------------  Statistics show page ---------------
    var statistics_show_page = document.querySelector('#statistics_show_page');

    if (statistics_show_page != undefined) {
        const now = new Date();
        let firstDay = null;
        let lastDay = null;
        if ((gon.date_from != undefined) && (gon.date_to != undefined)) {
            firstDay = new Date(gon.date_from.toString());
            lastDay = new Date(gon.date_to.toString());
        } else {
            // firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            // lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

            firstDay = new Date();
            lastDay = new Date(new Date().setDate(firstDay.getDate() - 30));
        }

        var date_from = $("#date_from").kendoDatePicker({
            value: firstDay,
            format: "dd MMMM yyyy",
            change: onFiltersChange,
        }).data("kendoDatePicker");

        var date_to = $("#date_to").kendoDatePicker({
            value: lastDay,
            format: "dd MMMM yyyy",
            change: onFiltersChange,
        }).data("kendoDatePicker");

        function set_loading_data_on_chart(chart_id, options) {
            var chart = Highcharts.chart(chart_id, options);
            set_chart_height(chart, chart_id);
            chart.showLoading(gon.loading_data + '...');
        }

        // Readraw graphs on any filter changes
        function onFiltersChange() {
            // console.log(date_from.value());
            // console.log(date_to.value());
            // console.log(selected_organization_id.toString());

            date_from_format_text = date_from.value().getFullYear().toString() + '-' + (date_from.value().getMonth() + 1).toString() + '-' + date_from.value().getDate().toString();
            date_to_format_text = date_to.value().getFullYear().toString() + '-' + (date_to.value().getMonth() + 1).toString() + '-' + date_to.value().getDate().toString();

            // let activity_type_id = activity_type.value();
            // let activity_type_id_str = activity_type_id.toString();

            // console.log(activity_type_id.value());

            //////////////////////////////////// Equipment Service ////////////////////////////////////
            // Update visits graph
            let data_activity_type_id = $('#statistics_equipment_visits').attr('data-activity-type-id');
            if (data_activity_type_id != undefined) {
                var request_params = 'date_from=' + date_from_format_text + '&date_to=' + date_to_format_text + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();

                set_loading_data_on_chart('statistics_equipment_visits', options_statistic_visits);

                Highcharts.ajax({
                    url: '/statistics_process/statistics_equipment_visits.json?' + request_params,
                    success: function (data) {
                        data = jQuery.parseJSON(data);
                        options_statistic_visits.series[0].data = data.user_activity;
                        options_statistic_visits.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();
                        var chart = Highcharts.chart('statistics_equipment_visits', options_statistic_visits);
                        chart.hideLoading();
                    }
                });
            }

            // Update products shown graph
            data_activity_type_id = $('#statistics_equipment_products_shown').attr('data-activity-type-id');
            if (data_activity_type_id != undefined) {
                request_params = 'date_from=' + date_from_format_text + '&date_to=' + date_to_format_text + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();

                set_loading_data_on_chart('statistics_equipment_products_shown', options_statistic_products_shown);

                Highcharts.ajax({
                    url: '/statistics_process/statistics_equipment_products_shown.json?' + request_params,
                    success: function (data) {
                        data = jQuery.parseJSON(data);
                        options_statistic_products_shown.series[0].data = data.product_shown_values;
                        options_statistic_products_shown.xAxis.categories = data.product_shown_keys;
                        options_statistic_products_shown.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();

                        let chart = Highcharts.chart('statistics_equipment_products_shown', options_statistic_products_shown);
                        set_chart_height(chart);
                        chart.hideLoading();
                    }
                });
            }


            // Update model manufacturers shown graph
            data_activity_type_id = $('#statistics_equipment_manufacturers_shown').attr('data-activity-type-id');
            if (data_activity_type_id != undefined) {
                request_params = 'date_from=' + date_from_format_text + '&date_to=' + date_to_format_text + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();

                set_loading_data_on_chart('statistics_equipment_manufacturers_shown', options_statistics_equipment_manufacturers_shown);

                Highcharts.ajax({
                    url: '/statistics_process/statistics_equipment_manufacturers_shown.json?' + request_params,
                    success: function (data) {
                        data = jQuery.parseJSON(data);
                        // Статистика подбора техники по производителям
                        options_statistics_equipment_manufacturers_shown.series[0].data = data.equipment_manufacturers_shown_values;
                        options_statistics_equipment_manufacturers_shown.xAxis.categories = data.equipment_manufacturers_shown_keys;
                        options_statistics_equipment_manufacturers_shown.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();

                        let chart = Highcharts.chart('statistics_equipment_manufacturers_shown', options_statistics_equipment_manufacturers_shown);
                        set_chart_height(chart);
                        chart.hideLoading();
                    }
                });
            }


            data_activity_type_id = $('#statistics_equipment_models_shown').attr('data-activity-type-id');
            if (data_activity_type_id) {
                request_params = 'date_from=' + date_from_format_text + '&date_to=' + date_to_format_text + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();

                set_loading_data_on_chart('statistics_equipment_models_shown', options_statistic_equipment_models_shown);

                Highcharts.ajax({
                    url: '/statistics_process/statistics_equipment_models_shown.json?' + request_params,
                    success: function (data) {
                        data = jQuery.parseJSON(data);
                        // Статистика подбора техники по модели
                        options_statistic_equipment_models_shown.series[0].data = data.equipment_model_shown_values;
                        options_statistic_equipment_models_shown.xAxis.categories = data.equipment_model_shown_keys;
                        options_statistic_equipment_models_shown.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();

                        let chart = Highcharts.chart('statistics_equipment_models_shown', options_statistic_equipment_models_shown);
                        set_chart_height(chart);
                        chart.hideLoading();
                    }
                });
            }

            //////////////////////////////////// Crossing Service ////////////////////////////////////
            // Update crossing visits graph
            data_activity_type_id = $('#statistics_crossing_visits').attr('data-activity-type-id');
            if (data_activity_type_id != undefined) {
                var request_params = 'date_from=' + date_from_format_text + '&date_to=' + date_to_format_text + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();

                set_loading_data_on_chart('statistics_crossing_visits', options_statistic_visits);

                Highcharts.ajax({
                    url: '/statistics_process/statistics_crossing_visits.json?' + request_params,
                    success: function (data) {
                        data = jQuery.parseJSON(data);
                        options_statistic_visits.series[0].data = data.user_activity;
                        options_statistic_visits.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();

                        let chart = Highcharts.chart('statistics_crossing_visits', options_statistic_visits);
                        chart.hideLoading();
                    }
                });
            }

            // Update products shown graph
            data_activity_type_id = $('#statistics_crossing_products_shown').attr('data-activity-type-id');
            if (data_activity_type_id != undefined) {
                request_params = 'date_from=' + date_from_format_text + '&date_to=' + date_to_format_text + '&user_api_activity_type_id=' + data_activity_type_id.toString() + '&organization_id=' + gon.user_organization_id.toString();

                set_loading_data_on_chart('statistics_crossing_products_shown', options_statistic_products_shown);

                Highcharts.ajax({
                    url: '/statistics_process/statistics_crossing_products_shown.json?' + request_params,
                    success: function (data) {
                        data = jQuery.parseJSON(data);
                        options_statistic_products_shown.series[0].data = data.product_shown_values;
                        options_statistic_products_shown.xAxis.categories = data.product_shown_keys;
                        options_statistic_products_shown.subtitle.text = data.date_from_format.toString() + ' - ' + data.date_to_format.toString();

                        let chart = Highcharts.chart('statistics_crossing_products_shown', options_statistic_products_shown);
                        set_chart_height(chart);
                        chart.hideLoading();
                    }
                });
            }


            const regexpSize = /([0-9]+)×([0-9]+)/;
            $(".statistics_download_link_with_dates").each(function (index) {


                // console.log(index + ": " + $(this).text() + ' attr: ' + $(this).attr('href'));
                // console.log(date_from_format_text);
                // console.log(date_to_format_text);

                // Remove Dates filter
                let new_link = $(this).attr('href').replace(/&date_from=(.+)&.+/, '');

                new_link = new_link + '&date_from=' + date_from_format_text + '&date_to=' + date_to_format_text;

                // Set new Date filtering to href
                $(this).attr('href', new_link);
                // console.log(index + ": " + $(this).text() + ' attr: ' + $(this).attr('href'));

            });


        }

    }

});

