
$(document).ready(function () {


    // --------------  Series Index page ---------------
    var series_index_page = document.querySelector('#series_grid');

    if (series_index_page != undefined) {

        var series_dictionary = {
            ru: {
                created_at: 'Дата создания',
                name_en: 'Производитель',
            },
            en: {
                created_at: 'Created at',
                name_en: 'Manufacturer',
            }
        };

        function getSeriesColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return series_dictionary[culture][word]
        }

        function resize_window_of_series() {
            $("#series_grid").children(".k-grid-content").height($(window).height() - 240);
        }

        $(window).resize(function () {
            resize_window_of_series();
        });


        $("#series_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/series.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            name_en: {type: "string"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_series();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
//            navigatable: true,
//            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/series/#=id#/edit">Edit</i></a></div>',
                    width: 120
                },
                {
                    title: getSeriesColumnNameByCulture(gon.locale, 'created_at'),  //"Дата создания",
                    field: "created_at",
                    // filterable: {
                    //     cell: {
                    //         showOperators: false
                    //     }
                    // },
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    width: 180
                },
                {
                    title: getSeriesColumnNameByCulture(gon.locale, 'name_en'),  //"Название отчета",
                    field: "name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },

            ]
        });

    }

});