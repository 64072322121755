var selectors_category_group_id = null;
var selectors_manufacturer_id = null;
var selectors_equipment_id = null;

$(document).ready(function () {

    // --------------  Selector 2 Index page ---------------
    var selector2_index_page = document.querySelector('#selector2');

    if (selector2_index_page != undefined) {

        // $("#selectors_series").data("kendoDropDownList").wrapper.hide();
        // $("#selectors_engine_sizes").data("kendoDropDownList").wrapper.hide();

        // reload page to refresh token
        function reload_page(){
            console.log('reload!');
            location.reload();
        }
        setTimeout(reload_page, 1700000);

        $('#drop-down-selectors').hide();

        function on_select_category_group(id) {
            // var dataItem = this.dataItem(e.item);

            selectors_category_group_id = id;

            var selectors_series = $("#selectors_series").data("kendoDropDownList");
            var selectors_engine_sizes = $("#selectors_engine_sizes").data("kendoDropDownList");

            // if "Cars" or "Vans (LCV)"
            if ((selectors_category_group_id == 2) || (selectors_category_group_id == 7)) {
                selectors_series.wrapper.show();
                selectors_engine_sizes.wrapper.show();
                // $("#selectors_series").closest(".k-widget").show();
                // $("#selectors_engine_sizes").closest(".k-widget").show();
                // console.log('show');
            } else {
                selectors_series.wrapper.hide();
                selectors_engine_sizes.wrapper.hide();
                // $("#selectors_series").closest(".k-widget").hide();
                // $("#selectors_engine_sizes").closest(".k-widget").hide();
                // console.log('hide');
            }

            // $('#tile-category-selector').addClass('hidden');
            // $('#drop-down-selectors').removeClass('hidden');

            // $('#tile-category-selector').fadeOut( "slow" ).addClass('hidden');
            // $('#drop-down-selectors').removeClass('hidden').fadeIn("slow");

            $('#tile-category-selector').fadeOut("slow").hide();
            $('#drop-down-selectors').fadeIn("slow");


            var selectors_models = $("#selectors_models").data("kendoDropDownList");
            selectors_models.enable(false);

            // var selectors_manufacturers = $("#selectors_manufacturers").data("kendoDropDownList");
            // selectors_manufacturers.refresh();
            $("#selectors_manufacturers").data("kendoDropDownList").dataSource.read();

            // $("#probe_test_type_name_id").val(dataItem.id.toString());
            // console.log("event :: select (" + dataItem.id + " : " + dataItem.name_en + ") " + selectors_category_group_id);
            // console.log('aAAAAA');

            $('#selector2_category_group_head_image').html(gon.plate_images[id]);
            $('#selector2_category_group_head_text').html(gon.plate_text[id]);
        }

        $('#selector2_new_search_btn').click(function (event) {
            event.preventDefault();
            event.stopPropagation();

            // $('#tile-category-selector').removeClass('hidden');
            // $('#drop-down-selectors').addClass('hidden');

            // $('#tile-category-selector').removeClass('hidden').fadeIn( "slow" );
            // $('#drop-down-selectors').fadeOut("slow").addClass('hidden');

            $('#tile-category-selector').fadeIn("slow");
            $('#drop-down-selectors').fadeOut("slow").hide();

            // console.log('aaaa');
        })

        $("#tile-category-cars").click(function () {
            // console.log('cars');
            on_select_category_group(2);
        })

        $("#tile-category-vans").click(function () {
            on_select_category_group(7);
            // console.log('vans');
        })

        $("#tile-category-commercial").click(function () {
            on_select_category_group(1);
            // console.log('commercial');
        })

        $("#tile-category-moto").click(function () {
            on_select_category_group(5);
            // console.log('moto');
        })

        $("#tile-category-agri").click(function () {
            on_select_category_group(3);
            // console.log('agri');
        })

        $("#tile-category-industrial").click(function () {
            on_select_category_group(6);
            // console.log('industrial');
        })

        $("#tile-category-off-road").click(function () {
            on_select_category_group(4);
            // console.log('off-road');
        })


        function on_change_category_group() {
            // disable model drop down
            var selectors_models = $("#selectors_models").data("kendoDropDownList");
            selectors_models.enable(false);
        }

        function get_category_image(id) {

            let template_image = ''
            switch (id) {
                case 1:
                    template_image = '<i class="fa-solid fa-truck-container"></i>';
                    break;
                case 2:
                    template_image = '<i class="fa-solid fa-car"></i>';
                    break;
                case 3:
                    template_image = '<i class="fa-solid fa-tractor"></i>';
                    break;
                case 4:
                    template_image = '<i class="fa-solid fa-truck-monster"></i>';
                    break;
                case 5:
                    template_image = '<i class="fa-solid fa-motorcycle"></i>';
                    break;
                case 6:
                    template_image = '<i class="fa-solid fa-train-subway"></i>';
                    break;
                case 7:
                    template_image = '<i class="fa-solid fa-truck"></i>';
                    break;

            }

            return template_image
        }

        function template_category_group(e) {
            template_image = get_category_image(e.id)
            return '<span class="flex flex-row k-state-default text-xl"><div class="text-gray-500 mr-4 flex w-8 justify-center items-center">' + template_image + '</div><div>' + e.name + '</div></span>';
        }

        function template_value_category_group(e) {
            template_image = get_category_image(e.id)
            // return template_image + '&nbsp;&nbsp;' + e.name;

            return '<div class="flex flex-row k-state-default"><div class="mr-2 flex w-8 justify-center items-center">' + template_image + '</div>' + e.name + '</div>';
        }


        function on_select_manufacturer(e) {
            var dataItem = this.dataItem(e.item);

            selectors_manufacturer_id = dataItem.id;


            // if NOT "Cars" or "Vans (LCV)" then filter
            if ((selectors_category_group_id != 2) && (selectors_category_group_id != 7)) {
                // filter model drop down
                var selectors_models = $("#selectors_models").data("kendoDropDownList");

                var newFilter = {
                    logic: "or",
                    filters: [
                        {
                            field: "selectors_manufacturer_id", operator: "eq", value: function () {
                                return selectors_manufacturer_id
                            }
                        },
                    ]
                };

                selectors_models.dataSource.filter(newFilter);
                selectors_models.refresh();

                // enable model drop down
                var selectors_models = $("#selectors_models").data("kendoDropDownList");
                selectors_models.enable(true);

            } else {
                // disable model drop down
                var selectors_models = $("#selectors_models").data("kendoDropDownList");
                selectors_models.enable(false);
            }


            // $("#probe_test_type_name_id").val(dataItem.id.toString());
            // console.log("event :: select (" + dataItem.id + " : " + dataItem.name_en + ") " + selectors_manufacturer_id );
        }


        var selectors_manufacturers = $("#selectors_manufacturers").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            height: 350,
            // cascadeFrom: "selectors_category_group",
            autoBind: false,
            // enable: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            optionLabel: gon.option_label_manufacturer,

            template: '<span class="flex flex-row k-state-default items-center"># if (data.logo_image) {#<img class="mr-4" src="#: data.logo_image #">#} else {# <div class="mr-12"></div> #}# #: data.name #</span>',

            valueTemplate: '<span class="flex flex-row k-state-default mr-2"># if (data.logo_image) {#<img class="mr-2" src="#: data.logo_image #">#}#  #: data.name #</span>',

            select: on_select_manufacturer,
            dataSource: {
                sort: {field: "name", dir: "asc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: function () {
                            return "/" + gon.locale + "/api/v1/category_groups/" + selectors_category_group_id + "/manufacturers";
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader("Authorization", atob(gon.req_tk));
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                            logo_image: {type: "string"}
                        }
                    }
                },
            }
        }).data("kendoDropDownList");


        function on_select_series(e) {
            var dataItem = this.dataItem(e.item);
            selectors_series_id = dataItem.id;

        }

        var selectors_series = $("#selectors_series").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            height: 350,
            cascadeFrom: "selectors_manufacturers",
            autoBind: false,

            // enable: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            optionLabel: gon.option_label_series,
            // template: '<span class="flex flex-row k-state-default text-xl"><div class="text-gray-500 mr-4">#= data.template_image # </div><div> #: data.name_en #</div></span>',
            // valueTemplate: '#= data.template_image # &nbsp;&nbsp; #: data.name_en #',
            select: on_select_series,
            dataSource: {
                sort: {field: "name", dir: "asc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: function () {
                            return "/" + gon.locale + "/api/v1/category_groups/" + selectors_category_group_id + "/manufacturers/" + selectors_manufacturer_id + "/series/";
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader("Authorization", atob(gon.req_tk));
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                        }
                    }
                },
            }
        }).data("kendoDropDownList");


        function on_select_engine_size(e) {
            var dataItem = this.dataItem(e.item);
            selectors_engine_size_volume = dataItem.volume;
            // console.log(selectors_engine_size_volume);

            var selectors_models = $("#selectors_models").data("kendoDropDownList");

            var newFilter = [
                {
                    field: "selectors_manufacturer_id", operator: "eq", value: function () {
                        return selectors_manufacturer_id
                    }
                },
                {
                    field: "selectors_series_id", operator: "eq", value: function () {
                        return selectors_series_id
                    }
                },
                {
                    field: "selectors_engine_size_volume", operator: "eq", value: function () {
                        return selectors_engine_size_volume
                    }
                },
            ];

            selectors_models.dataSource.filter(newFilter);
            selectors_models.refresh();

            // enable model drop down
            selectors_models.enable(true);


        }


        var selectors_engine_sizes = $("#selectors_engine_sizes").kendoDropDownList({
            filter: "startswith",
            dataTextField: "volume",
            dataValueField: "volume",
            height: 350,
            cascadeFrom: "selectors_series",
            autoBind: false,
            // enable: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            optionLabel: gon.option_label_engine_size,
            // template: '<span class="flex flex-row k-state-default text-xl"><div class="text-gray-500 mr-4">#= data.template_image # </div><div> #: data.name_en #</div></span>',
            // valueTemplate: '#= data.template_image # &nbsp;&nbsp; #: data.name_en #',
            select: on_select_engine_size,
            dataSource: {
                sort: {field: "volume", dir: "asc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: function () {
                            return "/" + gon.locale + "/api/v1/category_groups/" + selectors_category_group_id + "/manufacturers/" + selectors_manufacturer_id + "/series/" + selectors_series_id + "/engine_sizes/";
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader("Authorization", atob(gon.req_tk));
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            // id: {type: "integer"},
                            volume: {type: "float"},
                        }
                    }
                },
            }
        }).data("kendoDropDownList");


        function on_select_equipment(e) {
            var dataItem = this.dataItem(e.item);

            selectors_equipment_id = dataItem.mid

            if ((dataItem) && (dataItem.mid)) {
                window.location = "/" + gon.locale + "/selector2/0?mid=" + dataItem.mid + "&category_group_id=" + selectors_category_group_id;
            }
        }

        $("#selectors_models").kendoDropDownList({
            filter: "startswith",
            dataTextField: "model",
            dataValueField: "mid",
            height: 350,
            // cascadeFrom: "selectors_manufacturers",
            autoBind: false,
            enable: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            optionLabel: gon.option_label_model,
            template: '<div class="flex flex-row items-center"><div class="text-gray-900 mr-4">#= data.model # </div># if ((false) && (data.generations)) {#<div class="rounded-md bg-blue-50 px-2 py-0.5 mx-2">#: data.generations #</div>#}#  # if (data.fuel_type_name) {# <div>#: data.fuel_type_name #</div>#}#  # if (data.engine_output_kw){# <div class="text-green-700 rounded-md bg-green-100 px-2 py-0.5 mx-2">#: data.engine_output_kw # #: gon.kw #</div>  <div class="text-yellow-700 rounded-md bg-yellow-100 px-2 py-0.5 mx-2">#: data.engine_output_hp # #: gon.hp #</div> #}#  # if ((data.year_from) || (data.year_to)) {# <div class="text-gray-600 px-2 py-0.5 rounded-md bg-gray-100 mx-2"># if (data.year_from) {# #= data.year_from # #}# - # if (data.year_to){#  #= data.year_to # #}#</div>#}#</div>',
            // valueTemplate: '#= data.template_image # &nbsp;&nbsp; #: data.name_en #',
            select: on_select_equipment,
            dataSource: {
                sort: {field: "model", dir: "asc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: function () {
                            return "/" + gon.locale + "/api/v1/category_groups/" + selectors_category_group_id + "/manufacturers/" + selectors_manufacturer_id + "/equipment/";
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader("Authorization", atob(gon.req_tk));
                        },

                    },
                    // parameterMap: function (options, type) {
                    //     console.log(kendo.stringify(options));
                    //     return kendo.stringify(options)
                    // }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            mid: {type: "string"},
                            model: {type: "string"},
                            year_from: {type: "string"},
                            year_to: {type: "string"},
                            engine_output_kw: {type: "string"},
                            engine_output_hp: {type: "string"},
                            fuel_type_name_en: {type: "string"},
                            fuel_type_name: {type: "string"},
                            generations: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#selectors_search").kendoDropDownList({
            filter: "startswith",
            dataTextField: "model",
            dataValueField: "mid",
            height: 350,
            // cascadeFrom: "selectors_manufacturers",
            autoBind: false,
            // enable: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            optionLabel: gon.option_label_search,
            // template: '<div class="flex flex-row items-center"><div class="text-gray-900 mr-4">#= data.manufacturer_name # #= data.model # </div># if ((false) && (data.generations)) {#<div class="rounded-md bg-blue-50 px-2 py-0.5 mx-2">#: data.generations #</div>#} #<div>#: data.fuel_type_name #</div>  # if (data.engine_output_kw){# <div class="text-green-700 rounded-md bg-green-100 px-2 py-0.5 mx-2">#: data.engine_output_kw # #: gon.kw #</div>  <div class="text-yellow-700 rounded-md bg-yellow-100 px-2 py-0.5 mx-2">#: data.engine_output_hp # #: gon.hp #</div> #}#<div class="text-gray-600 px-2 py-0.5 rounded-md bg-gray-100 mx-2"># if (data.year_from) {# #= data.year_from # #}# - # if (data.year_to){#  #= data.year_to # #}#</div></div>',
            template: '<div class="flex flex-row items-center"><div class="text-gray-900 mr-4">#= data.manufacturer_name # #= data.model # </div># if ((false) && (data.generations)) {#<div class="rounded-md bg-blue-50 px-2 py-0.5 mx-2">#: data.generations #</div>#}#  # if (data.fuel_type_name) {# <div>#: data.fuel_type_name #</div>#}#  # if (data.engine_output_kw){# <div class="text-green-700 rounded-md bg-green-100 px-2 py-0.5 mx-2">#: data.engine_output_kw # #: gon.kw #</div>  <div class="text-yellow-700 rounded-md bg-yellow-100 px-2 py-0.5 mx-2">#: data.engine_output_hp # #: gon.hp #</div> #}#  # if ((data.year_from) || (data.year_to)) {# <div class="text-gray-600 px-2 py-0.5 rounded-md bg-gray-100 mx-2"># if (data.year_from) {# #= data.year_from # #}# - # if (data.year_to){#  #= data.year_to # #}#</div>#}#</div>',
            // valueTemplate: '#= data.template_image # &nbsp;&nbsp; #: data.name_en #',
            select: on_select_equipment,
            dataSource: {
                // sort: {field: "model", dir: "asc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: function () {
                            return "/" + gon.locale + "/api/v1/search/";
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader("Authorization", atob(gon.req_tk));
                        },

                    },
                    // parameterMap: function (options, type) {
                    //     console.log(kendo.stringify(options));
                    //     return kendo.stringify(options)
                    // }
                },
                serverPaging: true,
                pageSize: 100,
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            mid: {type: "string"},
                            model: {type: "string"},
                            year_from: {type: "string"},
                            year_to: {type: "string"},
                            engine_output_kw: {type: "string"},
                            engine_output_hp: {type: "string"},
                            fuel_type_name_en: {type: "string"},
                            fuel_type_name: {type: "string"},
                            generations: {type: "string"},
                            // region: {type: "string"},
                            manufacturer_name: {type: "string"},
                        }
                    }
                },
            }
        });

    }

});