$(document).ready(function () {

    // --------------  Statistics Index page ---------------
    var statistics_index_page = document.querySelector('#statistics_grid');

    if (statistics_index_page != undefined) {

        var statistics_dictionary = {
            ru: {
                name: 'Название',
                email: 'email',
                organization_name: "Организация",
                graphs_count: "Кол-во графиков"
            },
            en: {
                name: 'Name',
                email: 'email',
                organization_name: "Organization",
                graphs_count: "Graphs count"
            }
        };

        function getStatisticsColumnNameByCulture(culture, word) {
            return statistics_dictionary[culture][word]
        }

        function resize_window_of_statistics() {
            $("#statistics_grid").children(".k-grid-content").height($(window).height() - 280);
        }

        $(window).resize(function () {
            resize_window_of_statistics();
        });

        function statistics_table_row_coloring() {
            var grid = $("#statistics_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#ddecd5");
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f6b8c3");
                }
            });
        }

        $("#statistics_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/statistics.json"
                },
                group: [{
                    title: 'Organization',
                    field: "organization_name",
                    dir: "asc",
                }],
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                            organization_name: {type: "string"},
                            email: {type: "string"},
                            created_at: {type: "date"},
                            updated_at: {type: "date"},
                            graphs_count: {type: "integer"}
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_statistics();
                // statistics_table_row_coloring();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/statistics/#=id#">Show</i></a></div>',
                    width: 50
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/statistics/#=id#/edit">Edit</i></a></div>',
                    width: 50
                },
                {
                    title: getStatisticsColumnNameByCulture(gon.locale, 'organization_name'),  //"Название организации",
                    field: "organization_name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {
                    title: getStatisticsColumnNameByCulture(gon.locale, 'email'),  //
                    field: "email",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getStatisticsColumnNameByCulture(gon.locale, 'name'),  //
                    field: "name",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    }
                },
                {
                    title: getStatisticsColumnNameByCulture(gon.locale, 'graphs_count'),  //
                    field: "graphs_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    // filterable: false,
                    sortable: false,
                    width: 150
                },
            ]
        });

    }

});