$(document).ready(function () {

    // --------------  Equipment Index page ---------------
    var equipment_index_page = document.querySelector('#equipment_grid');

    if (equipment_index_page != undefined) {

        var equipment_dictionary = {
            ru: {
                created_at: 'Дата создания',
                model: 'Модель',
                model_original: 'Модель полная',
                drive_type_names: 'Тип привода',
                engine_volume: 'Объем двигателя',
                engine_output_kw: 'kW',
                engine_output_hp: 'hp',
                category_name: 'Категория',
                category_groups_name_en: 'Группа категорий',
                manufacturer_name: 'Производитель',
                fuel_type_name: 'Тип топлива',
                series_name: 'Серия',
                product_group_name: 'Подуктовая группа',
                year_from: 'Начало производства',
                year_to: 'Окончание производства',
                components_count: 'Количество компонентов',
                regions_name_en: 'Регион',
                is_active: 'Активен',
                equipment_generation_names: 'Генерации',
                show_image: 'Показ картинки',
                equipment_image_attached: 'Картинка',
                service_book_attached: 'Сервисная книжка',
                images_found: 'Найдены картинки',
                is_image_style_attached: "Стили заданы"
            },
            en: {
                created_at: 'Created at',
                model: 'Model',
                model_original: 'Model fullname',
                drive_type_names: 'Drive type',
                engine_volume: 'Engine size',
                engine_output_kw: 'kW',
                engine_output_hp: 'hp',
                category_name: 'Category',
                category_groups_name_en: 'Group of category',
                manufacturer_name: 'Manufacturer',
                fuel_type_name: 'Fuel type',
                series_name: 'Series',
                product_group_name: 'Product group',
                year_from: 'Start of manufacturing',
                year_to: 'End of manufacturing',
                components_count: 'Components count',
                regions_name_en: 'Region',
                is_active: 'Is active',
                equipment_generation_names: 'Generations',
                show_image: 'Show image',
                equipment_image_attached: 'Image',
                service_book_attached: 'Service book',
                images_found: 'Images found',
                is_image_style_attached: "Image styles set"
            }
        };

        function getEquipmentColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return equipment_dictionary[culture][word]
        }

        function resize_window_of_equipment() {
            $("#equipment_grid").children(".k-grid-content").height($(window).height() - 250);
        }

        $(window).resize(function () {
            resize_window_of_equipment();
        });


        function equipmentRowColoringOnDatabound() {
            var grid = $("#equipment_grid").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.is_active == 1) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#c5ebc7"); //green
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#eca6b0"); //red deactivated
                }
            });
        }

        $("#equipment_grid").kendoGrid({
            dataSource: {
                // type: "json",
                sort: {field: "created_at", dir: "desc"},
                transport: {
                    read: '/' + gon.locale + "/equipment.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            model: {type: "string"},
                            model_original: {type: "string"},
                            drive_type_names: {type: "string"},
                            equipment_generation_names: {type: "string"},
                            engine_volume: {type: "string"},
                            engine_output_kw: {type: "string"},
                            engine_output_hp: {type: "string"},
                            category_name_en: {type: "string"},
                            category_groups_name_en: {type: "string"},
                            manufacturer_name_en: {type: "string"},
                            fuel_type_name_en: {type: "string"},
                            series_name_en: {type: "string"},
                            product_group_name_en: {type: "string"},
                            year_from: {type: "string"},
                            year_to: {type: "string"},
                            capacities: {type: "string"},
                            components_count: {type: "string"},
                            regions_name_en:  {type: "string"},
                            is_active: {type: "boolean"},
                            show_image: {type: "boolean"},
                            equipment_image_attached: {type: "boolean"},
                            service_book_attached: {type: "boolean"},
                            images_found: {type: "boolean"},
                            images_found_at: {type: "string"},
                            is_image_style_attached: {type: "boolean"},
                        }
                    }
                },
                pageSize: 30,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            dataBound: function (e) {
                resize_window_of_equipment();
                equipmentRowColoringOnDatabound();
            },
            sortable: true,
            pageable: {
                refresh: true,
                // messages: {
                //     itemsPerPage: "",
                //     display: "{0} - {1} " + gon.i18n_samples_from + " {2} " + gon.i18n_samples
                // }
            },
            // pageable: true,
            navigatable: true,
            reorderable: true,
            resizable: true,
//            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/equipment/#=id#/">Show</i></a></div>',
                    width: 60
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/equipment/#=id#/edit">Edit</i></a></div>',
                    width: 60
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'regions_name_en'),  //"Название производителя",
                    field: "regions_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'manufacturer_name'),  //"Название производителя",
                    field: "manufacturer_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'model'),  //"Название модели",
                    field: "model",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 200
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'model_original'),  //"Название модели",
                    field: "model_original",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 200
                },

                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'drive_type_names'),  //"Drive types",
                    field: "drive_type_names",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'equipment_generation_names'),  //"Название модели",
                    field: "equipment_generation_names",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 200
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'engine_volume'),  //"Название модели",
                    field: "engine_volume",
                    filterable: {
                        cell: {showOperators: false}
                    },
                    width: 60
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'engine_output_kw'),  //"Название модели",
                    field: "engine_output_kw",
                    filterable: {
                        cell: {showOperators: false}
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'engine_output_hp'),  //"Название модели",
                    field: "engine_output_hp",
                    filterable: {
                        cell: {showOperators: false}
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'category_groups_name_en'),  //"Название категории",
                    field: "category_groups_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    sortable: false,
                    width: 150
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'category_name'),  //"Название категории",
                    field: "category_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'fuel_type_name'),  //"Название типа топлива",
                    field: "fuel_type_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'series_name'),  //"Название серии",
                    field: "series_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'product_group_name'),  //"Название группы",
                    field: "product_group_name_en",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 150
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'year_from'),  //"Год начала выпуска",
                    field: "year_from",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'year_to'),  //"Год окончания выпуска",
                    field: "year_to",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'is_active'),  //"Год окончания выпуска",
                    field: "is_active",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_active) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 70
                },

                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'show_image'),  //"Год окончания выпуска",
                    field: "show_image",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.show_image) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'images_found'),
                    field: "images_found",
                    template: '<div class="flex items-center justify-center"><span style="color:green">#:  data.images_found_at #</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'is_image_style_attached'),
                    field: "is_image_style_attached",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.is_image_style_attached) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 70
                },


                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'equipment_image_attached'),  //"Год окончания выпуска",
                    field: "equipment_image_attached",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.equipment_image_attached) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 70
                },
                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'service_book_attached'),  //"Год окончания выпуска",
                    field: "service_book_attached",
                    template: '<div class="flex items-center justify-center"><span style="color:green"># if (data.service_book_attached) {#<i class="fa-solid fa-circle-check"></i>#}#</span></div>',
                    filterable: {
                        cell: {
                            showOperators: false
                        },
                        messages: {isTrue: "Y ", isFalse: " N"}
                    },
                    width: 70
                },

                {
                    title: getEquipmentColumnNameByCulture(gon.locale, 'components_count'),  //"Год окончания выпуска",
                    field: "components_count",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 70
                },


            ]
        });

    }

});