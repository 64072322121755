$(document).ready(function () {

    // --------------  TestTypeMethods Index page ---------------
    var merge_industrial_codes_index_page = document.querySelector('#merge_industrial_codes_grid');

    if (merge_industrial_codes_index_page != undefined) {

        //Grid auto height resize
        function merge_industrial_codes_index_grid_resize() {
            var gridElement = $("#merge_industrial_codes_grid");
            gridElement.children(".k-grid-content").height($(window).height() - 278);
        }

        $(window).resize(function () {
            merge_industrial_codes_index_grid_resize();
        });


        function get_lubricant_codes_params(lubricant_codes_ids) {
            var selected_industrial_codes = [];

            $.each(lubricant_codes_ids, function( index, value ) {
                // console.log( index + ": " + value );

                $.ajax({
                    type: "GET",
                    async: false,
                    // data: {sample_id: gon.sample_id, fresh_sample_id: interpretation_fresh_sample_id},
                    url: "/lubricant_codes/" + value.toString() + '.json',
                    dataType: "json",

                    success: function (data) {
                        console.log(data['industry_reference']);

                        selected_industrial_codes.push(data['industry_reference']);

                    },
                    error: function (json) {
                        // centered_notification.show('Подбор оценки не возможен');
                    }
                });

            });

            // console.log(selected_industrial_codes);

            return selected_industrial_codes
        }


        let selected_tech_manufacturers_array = [];
        let selected_tech_manufacturer_target_id = null;

        // Assign source tech ids on select to variable array
        function onChange(arg) {
            // console.log("The selected product ids are: [" + this.selectedKeyNames().join(", ") + "]");

            selected_tech_manufacturers_array = this.selectedKeyNames();

            $('#selected_industrial_codes').html('');
            let selected_industrial_codes = get_lubricant_codes_params(selected_tech_manufacturers_array);

            console.log(selected_industrial_codes);

            let selected_industrial_codes_html = '';
            $.each(selected_industrial_codes, function( index, value ) {
                selected_industrial_codes_html = selected_industrial_codes_html + '<div class="rounded-md bg-gray-100 px-2 py-1">' + value.toString() + '</div>';
                // console.log(value);
            })

            // console.log(selected_industrial_codes_html);

            $('#selected_industrial_codes').html(selected_industrial_codes_html);

        }

        let merge_industrial_codes_grid = $("#merge_industrial_codes_grid").kendoGrid({
            dataSource: {
                sort: [

                    {field: "industry_reference", dir: "asc"},
                ],
                transport: {
                    read: {
                        url: "/lubricant_codes.json",
                        data: {
                            filter: {
                                logic: "and",
                                custom_filters: [
                                    {
                                        field: "show_association_counts",
                                        operator: "eq",
                                        value: true
                                    },
                                ]
                            },
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    model: {
                        id: "id",
                        fields: {
                            id: {type: "integer"},
                            created_at: {type: "date"},
                            industry_reference: {type: "string"},
                            lubricant_code_type_id: {type: "integer"},
                            lubricant_code_type_name_ru: {type: "string"},
                            lubricant_code_type_name_en: {type: "string"},
                            equipment_component_count: {type: "string"},
                            is_active: {type: "boolean"},
                        }
                    }
                },

                pageSize: 100,
                // total: function (response) {
                //     return response[0].TotalRecords;
                // },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
            sortable: {
                mode: "multiple",
                allowUnsort: true
            },
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // pageSizes: true,
                pageSizes: [30, 50, 100, 250], //, "all"
                buttonCount: 10,

                messages: {
                    itemsPerPage: "",
                    display: "{0} - {1} из {2} индустриальных кодов"
                }
            },

            // pageable: true,

            columnMenu: {
                sortable: false,
                filterable: true
            },
            // selectable: "true",
            persistSelection: true,

            // // filterable: "true",
            // filterable: {
            //     mode: "row"
            // },
            filterable: {
                mode: "row",
                // extra: false,
                // operators: {
                //     string: {
                //         startswith: "Starts with",
                //         eq: "Is equal to",
                //         neq: "Is not equal to"
                //     }
                // }
            },

            dataBound: function (e) {
                merge_industrial_codes_index_grid_resize();
            },
            change: onChange,

            columns: [
                {
                    // template: "<div align='center'>#= selectable #</div>",
                    selectable: true, width: "30px"
                },
                // {
                //     template: "<div align='center'><a class='grid-button-view' style='font-size: 11px;' href='/test_manufacturer_methods/#=id#/'>View</a></div>",
                //     width: 50,
                //     hidden: true
                // },
                {
                    title: "industry_reference",
                    field: "industry_reference",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                },
                {
                    title: "Кол-во образцов",
                    field: "samples_count",
                    filterable: false,
                    sortable: false,
                    width: 120
                },
            ]
        }).data("kendoGrid");

        // Assign target tech id to variable
        function on_target_tech_merge(e) {

            var dataItem = this.dataItem(e.item);
            // console.log(dataItem.id);

            selected_tech_manufacturer_target_id = dataItem.id;
        }

        let clean_sample_tech_manufacturer_dropdown = $("#merge_industrial_code_dropdown").kendoDropDownList({
            dataTextField: "industry_reference",
            dataValueField: "id",
            filter: "startswith",
            select: on_target_tech_merge,
            height: 500,
            dataSource: {
                sort: [
                    {field: "industry_reference", dir: "asc"},
                ],
                transport: {
                    read: {
                        url: "/lubricant_codes.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    model: {
                        id: "id",
                        fields: {
                            id: {type: "string"},
                            industry_reference: {type: "string"},
                            lubricant_code_type_name_ru: {type: "string"},

                        }
                    }
                },

                pageSize: 100,
                // total: function (response) {
                //     return response[0].TotalRecords;
                // },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },
        }).data("kendoDropDownList");


        function merge_sample_tech_manufacturer(to_clean_tech_manufacturer_ids, to_tech_manufacturer_id) {
            $.ajax({
                manufacturer: "GET",
                data: {
                    to_clean_tech_manufacturer_ids: to_clean_tech_manufacturer_ids,
                    to_tech_manufacturer_id: to_tech_manufacturer_id
                },
                url: "/merge_industrial_codes/merge.json?",
                dataType: "json",
                beforeSend: function () {
                    // Handle the beforeSend event
                    $('#button_to_combine').prop('disabled', true).removeClass('bg-indigo-600 hover:bg-indigo-700').addClass('bg-gray-600 hover:bg-gray-700');

                    console.log('before');
                },
                success: function (data) {
                    console.log(data);
                    // $('#interpretation_sample_grade_text_recommendations').html("");
                    $('#button_to_combine').prop('disabled', false).removeClass('bg-gray-600 hover:bg-gray-700').addClass('bg-indigo-600 hover:bg-indigo-700');

                    selected_tech_manufacturers_array = [];
                    selected_tech_manufacturer_target_id = null;
                    merge_industrial_codes_grid.clearSelection();

                    merge_industrial_codes_grid.dataSource.read();
                    clean_sample_tech_manufacturer_dropdown.dataSource.read();

                    // selected_tech_manufacturers_array = [];
                    // selected_tech_manufacturer_target_id = null;
                    //
                    // merge_industrial_codes_grid.selectedKeyNames([]);

                },
                error: function (json) {
                    selected_tech_manufacturers_array = [];
                    selected_tech_manufacturer_target_id = null;
                    merge_industrial_codes_grid.clearSelection();

                    console.log('error');
                    // centered_notification.show('Подбор оценки не возможен');
                    $('#button_to_combine').prop('disabled', false).removeClass('bg-gray-600 hover:bg-gray-700').addClass('bg-indigo-600 hover:bg-indigo-700');
                }
            });
        }

        $('#button_to_combine').click(function (e) {
            // console.log(this);

            if ((selected_tech_manufacturers_array.length > 0) && (selected_tech_manufacturer_target_id != null)) {
                merge_sample_tech_manufacturer(selected_tech_manufacturers_array, selected_tech_manufacturer_target_id);
            } else {
                console.log('No selected items')
            }
        })

    }

});