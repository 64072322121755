$(document).ready(function () {

    // --------------  Users Edit page ---------------
    var users_edit_page = document.querySelector('#product_manufacturer_organization_id');

    if (users_edit_page != undefined) {


        $("#product_manufacturer_organization_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "short_w_opf_name",
            dataValueField: "id",
            optionLabel: {
                id: gon.product_manufacturer_organization_id,
                short_w_opf_name: gon.product_manufacturer_organization_short_w_opf_name,
            },
            template: '<span class="k-state-default"><h3>#: data.short_w_opf_name #</h3><p>Контракт: #: data.contract_number #</p> <p>ИНН: #: data.inn #</p>  </span>',
            dataSource: {
                serverFiltering: true,
                // sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        dataType: "json",
                        url: "/organizations.json",
                    },
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            short_w_opf_name: {type: "string"},
                            contract_number: {type: "string"},
                            inn: {type: "string"},
                        }
                    }
                },
            }
        });







        // ----------- Show Modal copy product manufacturer -----------

        $("#product_manufacturer_copy_to_organization_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "short_w_opf_name",
            dataValueField: "id",
            // optionLabel: {
            //     id: gon.product_manufacturer_organization_id,
            //     name_en: gon.product_manufacturer_organization_short_w_opf_name,
            // },
            template: '<span class="k-state-default"><h3>#: data.short_w_opf_name #</h3><p>Контракт: #: data.contract_number #</p> <p>ИНН: #: data.inn #</p>  </span>',
            dataSource: {
                serverFiltering: true,
                // sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        dataType: "json",
                        url: "/organizations.json",
                    },
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            short_w_opf_name: {type: "string"},
                            contract_number: {type: "string"},
                            inn: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#product_manufacturer_copy_button").click(function (e) {
            e.preventDefault();
            $('#modal_window_product_manufacturer_copy').fadeTo(250, 1, function () {
                $(this).show()
            })
            $('#modal_window_product_manufacturer_copy_overlay').fadeTo(250, 1, function () {
                $(this).show()
            })
            // $("#application_zebra_label_input_text").focus();
        });

        function hide_modal_overlay(e) {
            e.preventDefault();

            $('#modal_window_product_manufacturer_copy_overlay').fadeTo(250, 0, function () {
                $(this).hide()
            })

            $('#modal_window_product_manufacturer_copy').fadeTo(250, 0, function () {
                $(this).hide()
            })
        }

        $("#modal_window_product_manufacturer_copy_cancel_button").click(function (e) {
            hide_modal_overlay(e);
        })

        $("#modal_window_product_manufacturer_copy_grayout").click(function (e) {
            hide_modal_overlay(e);
        })

        function product_manufacturer_check_is_copy () {
            $('#is_make_copy_of_product_manufacturer').prop('checked', true);
            return $.when('set copy');
        }

        function product_manufacturer_submit_update_form () {
            $('#product_manufacturer_form_save_button').trigger('click');
            return $.when('submit form');
        }

        $("#modal_window_product_manufacturer_make_copy_button").click(function (e) {
            e.preventDefault();

            product_manufacturer_check_is_copy().then(product_manufacturer_submit_update_form());

        });

    }

});