var selectors_category_group_id = null;
var selectors_manufacturer_id = null;
var selectors_equipment_id = null;

$(document).ready(function () {

    // --------------  Series Index page ---------------
    var series_index_page = document.querySelector('#reverse_crossing_selectors_search');

    if (series_index_page != undefined) {

        // reload page to refresh token after < 30min
        function reload_page(){
            console.log('reload!');
            location.reload();
        }
        setTimeout(reload_page, 1700000);

        // hide selectors if service is not available now and show warning
        if (gon.not_available == true) {

            $('#service_available').addClass('hidden');
            $('#service_not_available').removeClass('hidden');

        } else {
            $('#service_available').removeClass('hidden');
        }


        function on_select_crossing_liquid(e) {
            var dataItem = this.dataItem(e.item);

            selectors_equipment_id = dataItem.mid

            if ((dataItem) && (dataItem.mid)) {
                window.location = '/' + gon.locale + "/analytics/reverse_crossings/0?mid=" + dataItem.mid +
                    "&session=" + gon.req_tk;
            }
        }


        $("#reverse_crossing_selectors_search").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "mid",
            height: 350,

            autoBind: false,
            // enable: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            optionLabel: gon.option_label_search,

            template: '<div class="flex flex-row items-center"><div class="rounded-md bg-green-50 px-2 py-0.5 mx-2">#= data.manufacturer_name #</div><div class="text-gray-900 mr-4"> #= data.name # </div></div>',
            // template: '<div class="flex flex-row items-center"><div class="text-gray-900 mr-4">#= data.manufacturer_name # #= data.model # </div># if ((false) && (data.generations)) {#<div class="rounded-md bg-blue-50 px-2 py-0.5 mx-2">#: data.generations #</div>#}#  # if (data.fuel_type_name) {# <div>#: data.fuel_type_name #</div>#}#  # if (data.engine_output_kw){# <div class="text-green-700 rounded-md bg-green-100 px-2 py-0.5 mx-2">#: data.engine_output_kw # #: gon.kw #</div>  <div class="text-yellow-700 rounded-md bg-yellow-100 px-2 py-0.5 mx-2">#: data.engine_output_hp # #: gon.hp #</div> #}#  # if ((data.year_from) || (data.year_to)) {# <div class="text-gray-600 px-2 py-0.5 rounded-md bg-gray-100 mx-2"># if (data.year_from) {# #= data.year_from # #}# - # if (data.year_to){#  #= data.year_to # #}#</div>#}#</div>',

            // valueTemplate: '#= data.template_image # &nbsp;&nbsp; #: data.name_en #',
            select: on_select_crossing_liquid,
            dataSource: {
                // sort: {field: "model", dir: "asc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: function () {
                            return '/' + gon.locale + "/analytics/reverse_crossings_search.json";
                        },
                        // beforeSend: function (req) {
                        //     req.setRequestHeader("Authorization", atob(gon.req_tk));
                        // },

                    },
                    // parameterMap: function (options, type) {
                    //     console.log(kendo.stringify(options));
                    //     return kendo.stringify(options)
                    // }
                },
                serverPaging: true,
                pageSize: 100,
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            mid: {type: "string"},
                            name: {type: "string"},
                            manufacturer_name: {type: "string"},
                        }
                    }
                },
            }
        });

    }

});