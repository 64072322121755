$(document).ready(function () {

    // --------------  Equipment Components Edit Page ---------------
    var equipment_components_edit_page = document.querySelector('#equipment_components_page');

    if (equipment_components_edit_page != undefined) {

        $("#equipment_component_equipment_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "model",
            dataValueField: "id",
            optionLabel: {
                id: gon.equipment_equipment_components_id,
                model: gon.equipment_equipment_components_model,
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/equipment.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            model: {type: "string"},
                            year_from: {type: "string"},
                            year_to: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#equipment_component_component_type_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            // autoBind: false,
            optionLabel: {
                id: gon.equipment_component_type_id,
                name_en: gon.equipment_component_type_name,
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/component_types.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#equipment_component_operating_regime_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_operating_regime_name_en,
                id: gon.equipment_operating_regime_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/operating_regimes.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#equipment_component_oci_units_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name_en",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_oci_units_name_en,
                id: gon.equipment_oci_units_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/oci_units.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                        }
                    }
                },
            }
        });

        $("#equipment_component_drive_type_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_drive_type_name,
                id: gon.equipment_drive_type_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/drive_types.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#equipment_engine_size_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "engine_size_s",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_engine_size_name_en,
                id: gon.equipment_engine_size_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/engine_sizes.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            engine_size_s: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#equipment_drive_type_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            optionLabel: {
                name_en: gon.equipment_drive_type_name_en,
                id: gon.equipment_drive_type_id
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        url: "/drive_types.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                        }
                    }
                },
            }
        });


        $("#equipment_component_lubricant_code_ids").kendoMultiSelect({
            placeholder: "Please Select a industry references",
            dataTextField: "industry_reference",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.lubricant_codes,
            itemTemplate: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
            // valueTemplate: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
            // template: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
            dataSource: {
                serverFiltering: true,
                sort: {field: "industry_reference", dir: "asc"},
                transport: {
                    read: {
                        url: "/lubricant_codes.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            industry_reference: {type: "string"},
                            is_active: {type: "boolean"}
                        }
                    }
                }
            }
        }).data("kendoMultiSelect");


        $("#equipment_component_oil_viscosity_ids").kendoMultiSelect({
            placeholder: "Please Select a Viscosities",
            dataTextField: "name",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.oil_viscosities,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name", dir: "asc"},
                transport: {
                    read: {
                        url: "/oil_viscosities.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                            oil_viscosity_standard_name: {type: "string"},
                        }
                    }
                }
            }
        });


        $("#equipment_component_capacity_ids").kendoMultiSelect({
            placeholder: "Please Select a Capacities",
            dataTextField: "volume",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.capacities,
            dataSource: {
                serverFiltering: true,
                sort: {field: "volume", dir: "asc"},
                transport: {
                    read: {
                        url: "/capacities.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            volume: {type: "string"},
                            capacity_unit_id: {type: "string"},
                            capacity_unit_name_en: {type: "string"},
                        }
                    }
                }
            }
        });

        $("#equipment_component_initial_capacity_ids").kendoMultiSelect({
            placeholder: "Please Select a Initial capacities",
            dataTextField: "volume",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.initial_capacities,
            dataSource: {
                serverFiltering: true,
                sort: {field: "volume", dir: "asc"},
                transport: {
                    read: {
                        url: "/initial_capacities.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            volume: {type: "string"},
                            capacity_unit_id: {type: "string"},
                            capacity_unit_name_en: {type: "string"},
                        }
                    }
                }
            }
        });


        function lubricant_code_databound(e) {
            console.log('databound');

            $(this).closest('.assign_lubricant_code').removeClass('assign_lubricant_code');
            // e.removeClass('assign_lubricant_code')
        }

        function oil_viscosity_databound(e) {
            console.log('databound');

            $(this).closest('.assign_viscosity').removeClass('assign_viscosity');
            // e.removeClass('assign_lubricant_code')
        }


        // Liquid pairs: Assign on page load to all elements of Lubricant codes
        $('[id ^=equipment_component_equipment_component_liquid_pairs_attributes_][id $=_lubricant_code_id]').kendoDropDownList({
            filter: "startswith",
            placeholder: "Please Select a industry references",
            dataTextField: "industry_reference",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            // value: gon.lubricant_codes,
            // itemTemplate: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
            // valueTemplate: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
            // template: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',

            // dataBound: lubricant_code_databound,
            dataSource: gon.lubricant_codes_data,
            // dataSource: {
            //     serverFiltering: true,
            //     sort: {field: "industry_reference", dir: "asc"},
            //     transport: {
            //         read: {
            //             url: "/lubricant_codes.json",
            //             data: {
            //                 filter: {
            //                     logic: "and",
            //                     filters: [
            //                         {field: "paging_enable", operator: "eq", value: false}
            //                     ]
            //                 }
            //             }
            //         }
            //     },
            //     schema: {
            //         total: "total",
            //         data: "results",
            //         id: "id",
            //         model: {
            //             fields: {
            //                 id: {type: "integer"},
            //                 industry_reference: {type: "string"},
            //                 is_active: {type: "boolean"}
            //             }
            //         }
            //     }
            // }
        });

        $("[id ^=equipment_component_equipment_component_liquid_pairs_attributes_][id $=_lubricant_code_id]").removeClass('assign_lubricant_code');

        // Liquid pairs: Assign on page load to all elements of Oil viscosities
        $("[id ^=equipment_component_equipment_component_liquid_pairs_attributes_][id $=_oil_viscosity_id]").kendoDropDownList({
            filter: "startswith",
            placeholder: "Please Select a Viscosities",
            dataTextField: "name",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            dataSource: gon.viscosities_data,
        });
        $("[id ^=equipment_component_equipment_component_liquid_pairs_attributes_][id $=_oil_viscosity_id]").removeClass('assign_viscosity');


        // Liquid pairs: Assign on page load to all elements of Organizations
        $("[id ^=equipment_component_equipment_component_liquid_pairs_attributes_][id $=_organization_id]").kendoDropDownList({
            filter: "contains",
            placeholder: "Please Select the Organization",
            dataTextField: "short_w_opf_name",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            dataSource: gon.organizations_data,
        });
        $("[id ^=equipment_component_equipment_component_liquid_pairs_attributes_][id $=_organization_id]").removeClass('assign_organization');


        $(document).on('click', '#add_liquid_pair', function (e) {

            // $(this).closest('.assign_lubricant_code').removeClass('assign_lubricant_code');
            // $(this).closest('.assign_viscosity').removeClass('assign_viscosity');

            $('.assign_lubricant_code').each(function (index) {
                // console.log(index + ": ");

                $(this).removeClass('assign_lubricant_code');

                $(this).kendoDropDownList({
                    filter: "contains",
                    placeholder: "Please Select a industry references",
                    dataTextField: "industry_reference",
                    dataValueField: "id",
                    autoBind: false,
                    height: 500,
                    // value: gon.lubricant_codes,
                    // itemTemplate: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
                    // valueTemplate: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
                    // template: '# if (is_active == false) { #  <div class="text-red-500 bg-red-50">  # } #   #: data.industry_reference #   #if (data.industry_reference==null) {# (reference miss) #}#  # if (is_active == false) { #  </div>  # } # ',
                    dataSource: gon.lubricant_codes_data,
                    value: '',
                    // dataSource: {
                    //     serverFiltering: true,
                    //     sort: {field: "industry_reference", dir: "asc"},
                    //     transport: {
                    //         read: {
                    //             url: "/lubricant_codes.json",
                    //             data: {
                    //                 filter: {
                    //                     logic: "and",
                    //                     filters: [
                    //                         {field: "paging_enable", operator: "eq", value: true}
                    //                     ]
                    //                 }
                    //             }
                    //         }
                    //     },
                    //     schema: {
                    //         total: "total",
                    //         data: "results",
                    //         id: "id",
                    //         model: {
                    //             fields: {
                    //                 id: {type: "integer"},
                    //                 industry_reference: {type: "string"},
                    //                 is_active: {type: "boolean"}
                    //             }
                    //         }
                    //     }
                    // }
                });

            });

            $('.assign_viscosity').each(function (index) {

                $(this).removeClass('assign_viscosity');

                $(this).kendoDropDownList({
                    filter: "contains",
                    placeholder: "Please Select a Viscosities",
                    dataTextField: "name",
                    dataValueField: "id",
                    autoBind: false,
                    height: 500,
                    // optionLabel: //"Select...",
                    //     {
                    //     id : 0,
                    //     name: "Select..."
                    // },
                    // optionLabel: "Select...",
                    value: '',
                    dataSource: gon.viscosities_data,
                    // dataSource: {
                    //     serverFiltering: true,
                    //     sort: {field: "name", dir: "asc"},
                    //     transport: {
                    //         read: {
                    //             url: "/oil_viscosities.json",
                    //             data: {
                    //                 filter: {
                    //                     logic: "and",
                    //                     filters: [
                    //                         {field: "paging_enable", operator: "eq", value: true}
                    //                     ]
                    //                 }
                    //             }
                    //         }
                    //     },
                    //     schema: {
                    //         total: "total",
                    //         data: "results",
                    //         id: "id",
                    //         model: {
                    //             fields: {
                    //                 id: {type: "integer"},
                    //                 name: {type: "string"},
                    //                 oil_viscosity_standard_name: {type: "string"},
                    //             }
                    //         }
                    //     }
                    // }
                })

            });


        });



    }

});